
import React from 'react';
import './boxie.css'; // Import the CSS file
const url = "https://live.avertisystems.com/";


const Dynamicpage = (props) => {
  const { selectProduct } = props;
  return (
    <div className="dynamicpage-container" style={{backgroundColor:"white"}}>
      <img
        src={`${url}${selectProduct && selectProduct.img}`}
        alt="Product"
        className="dynamicpage-img"
      />
    </div>
  );
};

export default Dynamicpage;
