import React, { Component } from "react";
import { connect } from "react-redux";
import "./custom.css";
import Section from "./layouts/Section";
import { GetProfile, UpdateProfile, UploadAvatar, ChangePassword,showDataSky,UpdateProfileSkyflow } from "../actions";
import Spinner from "./subcomponent/spinner/Spinner";
import "./new.css";
import "./prashant.css";
import { withAvertiLoader } from "../loaderHoc";

class UserSetting extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userProfile: {},
      errors: {},
      loader: false
    };
    this.handleInput = this.handleInput.bind(this);
    this.uploadAvatar = this.uploadAvatar.bind(this);
    this.showData = this.showData.bind(this);
    this.pwdFormRef = React.createRef();
    this.changePassword = this.changePassword.bind(this);

  }

  componentDidMount() {
    this.props.GetProfile();
  }
  
  showData(id){
    this.props.showDataSky(id);
  }

  handleInput(e, field) {
    let userProfile = this.state.userProfile;
    userProfile[field] = e.target.value;
    this.setState({ userProfile });
  }

  handleValidation() {
    let fields = this.state.userProfile;
    // console.log(fields)
    let errors = {};
    let formIsValid = true;
    if (fields["first_name"] === null || !fields["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "Please Enter First Name.";
    }

    if (fields["email"] === null || !fields["email"]) {
      formIsValid = false;
      errors["email"] = "Please Enter valid email. ";
    }

    if (fields["email"] === null || typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid.";
      }
    }
    if (fields["phone"] === null || !fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Please Enter the Contact Number.";
    }
    if (fields["phone"] !== null && fields["phone"].length !== 10) {
      formIsValid = false;
      errors["phone"] = "Please Enter Correct Number.";
    }

    if (
      fields["zip_code"] === null ||
      (typeof fields["zip_code"] !== "undefined" &&
        (fields["zip_code"].length < 4 || fields["zip_code"].length > 6))
    ) {
      formIsValid = false;
      errors["zip_code"] = "Please Enter Valid zip code.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  updateProfile(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      this.props.UpdateProfileSkyflow(this.state.userProfile);
    } else {
      console.log("invalid");
    }
  }

  static getDerivedStateFromProps(props, state) {
    // console.log(props)
    if (props) {
      return { ...state, userProfile: props.userDetail };
    }
    return state;
  }

  uploadAvatar(e) {
    const file = e.target.files[0];
    // console.log('file', file)
    const formData = new FormData();
    formData.append("profile_image", file);
    this.props.UploadAvatar(formData);
  }

  changePassword(e) {

    e.preventDefault();
    const fd = new FormData(this.pwdFormRef.current)

    const p1 = fd.get('password')
    const p2 = fd.get('cpassword')

    if(p1.length < 6) {
      window.toastr.error('Password should be atleast 6 characters')
    }
    else if(p1 !== p2) {
      window.toastr.error('Password and confirm password mismatched')
    }
    else {
      this.props.ChangePassword(fd);
    }
  }

  componentDidUpdate() {
    if(this.props.loader) {
      this.props.showLoader()
    }
    else {
      this.props.hideLoader()
    }

    if(this.props.status) {
      this.pwdFormRef.current.reset()
      window.toastr.success('You\'ve successfully changed your password')
    }

  }

  render() {

    return (
      <Section
        allNotification={false}
        searchPopup={false}
        title={"User Setting"}
      >
        {this.props.loaderDiv}
        <React.Fragment>
          <div className="">
            <div className="balance-area pd-top-25">
              <div className="container">
                <div className="section-title usersetting-section">
                  <h3 className="title">Update Your Information</h3>
                </div>
                <div className="balance-area-bg pt-3 bg-user-setting">
                  <div className="ba-balance-inner text-center __updateprofilebg">
                    <div style={{ position: "relative" }} className="icon">
                      <img
                        className="avatar-img"
                        src={
                          this.state.userProfile.avatar
                            ? this.state.userProfile?.avatar
                            : process.env.PUBLIC_URL + "/assets/img/other/1.png"
                        }
                        alt="img"
                      />
                      <input
                        className="avatar-file"
                        onChange={this.uploadAvatar}
                        type={"file"}
                        accept="image/*"
                      />
                    </div>
                    <h5 className="title mb-0">
                      {this.state.userProfile?.first_name +
                        " " +
                        this.state.userProfile?.last_name}
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="transaction-details mg-bottom-35">
              <div className="container user-setting-section-o">
                <div className="row">
                  <div className="col-12 col-md-12 px-4">
                    <div className="section-title" style={{paddingBottom:"15px"}}>
                      <h3  className="title">Profile Settings</h3>
                      <button onClick={()=>this.showData(this.state.userProfile.id)} style={{width: "100px",borderRadius: "5px", padding:"3px 0px"}} className="btn-danger pull-right">Reveal</button>
                      
                    </div>
                    <form style={{width:'100%'}} action="#" className="contact-form-wrap">
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="First Name"
                            onChange={(e) => this.handleInput(e, "first_name")}
                            value={this.state.userProfile.first_name || ""}
                          />
                          {this.state.errors.first_name !== undefined ? (
                            <p className="text-center text-danger">
                              {this.state.errors.first_name}
                            </p>
                          ) : null}
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Middle Name"
                            onChange={(e) => this.handleInput(e, "middle_name")}
                            value={this.state.userProfile.middle_name || ""}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Last Name"
                            onChange={(e) => this.handleInput(e, "last_name")}
                            value={this.state.userProfile.last_name || ""}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="E-mail Address"
                            onChange={(e) => this.handleInput(e, "email")}
                            value={this.state.userProfile.email || ""}
                          />
                          {this.state.errors.email !== undefined ? (
                            <p className="text-center text-danger">
                              {this.state.errors.email}
                            </p>
                          ) : null}
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Phone Number"
                            onChange={(e) => this.handleInput(e, "phone")}
                            value={this.state.userProfile.phone || ""}
                          />
                          {this.state.errors.phone !== undefined ? (
                            <p className="text-center text-danger">
                              {this.state.errors.phone}
                            </p>
                          ) : null}
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="City"
                            onChange={(e) => this.handleInput(e, "city")}
                            value={this.state.userProfile.city || ""}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="State"
                            onChange={(e) => this.handleInput(e, "state")}
                            value={this.state.userProfile.state || ""}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Country"
                            onChange={(e) => this.handleInput(e, "country")}
                            value={this.state.userProfile.country || ""}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Postal Code"
                            onChange={(e) => this.handleInput(e, "zip_code")}
                            value={this.state.userProfile.zip_code || ""}
                          />
                          {this.state.errors.zip_code !== undefined ? (
                            <p className="text-center text-danger">
                              {this.state.errors.zip_code}
                            </p>
                          ) : null}
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Address"
                            onChange={(e) => this.handleInput(e, "address")}
                            value={this.state.userProfile.address || ""}
                          />
                        </li>
                      </ul>
                      <button
                        type="button"
                        className="btn-c btn-ivory ripple mr-1 mb-2"
                        onClick={this.updateProfile.bind(this)}
                      >
                        {" "}
                        Update Profile{" "}
                        {this.props.loader ? <Spinner size="mini" /> : null}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="password-details mg-bottom-35">
              <div className="container user-setting-section-o">
                <div className="row">
                  <div className="col-12 col-md-12 px-4">
                    <div className="section-title" style={{paddingBottom:"15px"}}>
                      <h3 className="title">Change Password</h3>
                      <form ref={this.pwdFormRef} className="form-horizontal" style={{width:'100%'}} onSubmit={this.changePassword}>
                          <div className="form-group">
                            <input className="form-control p-4 rounded-pill _usersettingsinput" type="password" name="password" placeholder="New Password" required />
                          </div>
                          <div className="form-group">
                            <input className="form-control p-4 rounded-pill _usersettingsinput" type="password" name="cpassword" placeholder="Confirm Password" required />
                          </div>
                          <button type="submit" className="btn-c btn-ivory ripple mr-1 mb-2">Change Password</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div style={{ height: "10px" }}></div>
          </div>
        </React.Fragment>
      </Section>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    userDetail: state.user.userDetail,
    loader: state.user.loader,
    status: state.user.status,
  };
};

export default connect(mapStateToProps, {
  GetProfile,
  UpdateProfile,
  UploadAvatar,
  ChangePassword,
  showDataSky,
  UpdateProfileSkyflow
})( withAvertiLoader(UserSetting));
