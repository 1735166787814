import React, { useState } from 'react';

const SmoothImage = ({ src, alt, onClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="image-container" style={{ position: 'relative', height:"100%" }}>
      {!imageLoaded && (
        <div 
          className="placeholder"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#f0f0f0',
          }}
        />
      )}
      <img
        src={src}
        alt={alt}
        loading="lazy"
        onClick={onClick}
        onLoad={() => setImageLoaded(true)}
        style={{
          opacity: imageLoaded ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
      />
    </div>
  );
};

export default SmoothImage;