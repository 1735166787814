import axios from "axios";
import auth from "../api/auth";
import { GET_MEETING_LIST, GET_MEETING_LIST_ERROR, LOADER } from "../store/redux-constant";
import { ADD_EVENT, ADD_ERROR, FETCH_EVENTS, EVENTS_ERRORS,FETCH_TASKS,TASKS_ERRORS } from "../store/redux-event-constant";

const headers = {
    'Content-Type': 'application/json'
}

export const addEvent = (data) => {
    return async (dispatch) => {
        try {
            headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
            dispatch({ type: LOADER, payload: {} })
            const response = await auth.post('/events', data, { headers })
            if (response.status === 200 || response.status === 201) {
                dispatch({ type: ADD_EVENT, payload: response.data.data })
            }
            else {
                dispatch({ type: ADD_ERROR, payload: {} })
            }
        } catch (error) {
            dispatch({ type: ADD_ERROR, payload: {} })
        }
    }
}

export const addTask = (data) => {
    return async (dispatch) => {
        try {
            headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
            dispatch({ type: LOADER, payload: {} })
            const response = await auth.post('/task_new/store', data, { headers })
            if (response.status === 200 || response.status === 201) {
                dispatch({ type: ADD_EVENT, payload: response.data.data })
            }
            else {
                dispatch({ type: ADD_ERROR, payload: {} })
            }
        } catch (error) {
            dispatch({ type: ADD_ERROR, payload: {} })
        }
    }
}

export const fetchEvents = () => {
    return async (dispatch) => {
        try {
            headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
            dispatch({ type: LOADER, payload: {} })
            const response = await auth.get('/events', { headers })
            if (response.status === 200 || response.status === 201) {
                dispatch({ type: FETCH_EVENTS, payload: response.data.data })
                // console.log("event",response.data.data);
            }
            else {
                dispatch({ type: EVENTS_ERRORS, payload: {} })
            }
        } catch (error) {
            dispatch({ type: EVENTS_ERRORS, payload: {} })
        }
    }
}

export const fetchMeetingList = () => {
    return async (dispatch) => {
        try {
            headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
            dispatch({ type: LOADER, payload: {} })
            const response = await auth.get('/zoom/list', { headers })
            if (response.status === 200 || response.status === 201) {
                dispatch({ type: GET_MEETING_LIST, payload: response.data.data })
                console.log("meeting", response.data.data);
            }
            else {
                dispatch({ type: GET_MEETING_LIST_ERROR, payload: {} })
            }
        } catch (error) {
            dispatch({ type: GET_MEETING_LIST_ERROR, payload: {} })
        }
    }
}

export const getAllTasks = async () => {
  try {
      headers['Authorization'] = 'Bearer ' + window.localStorage.getItem('AUTH_TOKEN');
      const response = await auth.get('/task_new/task_calender', { headers });
      if (response.status === 200) {
          return response.data; 
      } else {
          throw new Error("Error fetching tasks");
      }
  } catch (error) {
      throw new Error("Error fetching tasks");
  }
}


export const GetGroup = async (id) => {
    const token = window.localStorage.getItem('AUTH_TOKEN');
    try {
        const response = await axios.get(`https://live.avertisystems.com/api/v1/messages/all?type=custom_life&user_id=892`, {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'Application/json',
                'Accept': 'Application/json',
            },
        });

        if (response.data && response.data.data) {
            console.log(response.data.data, "response.data.data");
            return response.data.data;
        } else {
            console.error("No data found");
            return null;
        }
    } catch (error) {
        console.error("Error fetching group data", error);
        return null;
    }
}
