import React, { useState, useEffect } from "react";
import "./ChildHomeMolecule.css";

import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { ButtonGroup } from "react-bootstrap";
import Trialcam from "./Trialcam";
import CloseIcon from "@mui/icons-material/Close";
import SelectItem from "./SelectItem";
import Draggable from "react-draggable";

import { indexy } from "../api/auth";
import { folder } from "../api/auth";
import { getfolder } from "../api/auth";
import { deletefolder } from "../api/auth";
import { getsubfolder } from "../api/auth";
import { deletesubfolder } from "../api/auth";
import { getsubfolderdata } from "../api/auth";
import { OutfitCrt } from "../api/auth";
import { getsubfolderoutfit } from "../api/auth";
import SmoothImage from "./SmoothImage";
import imageCompression from "browser-image-compression";

const CustomTab = ({ label, active, onClick }) => (
	<div className={`tabical ${active ? "active" : ""}`} onClick={onClick}>
		{label}
	</div>
);

const url = "https://live.avertisystems.com/";

const Collection = (props) => {
	const {
		clientId,
		setAllitems,
		setNewBoxes,
		setSelectedBoxIndex,
		selectedBoxIndex,
		setDragImageBox,
		formData,
		setFormData,
		selecteddtFolderBox,
		setselecteddtFolderBox,
		allitem,
		getOutfitData,
	} = props;
	const [hide, setHide] = useState(false);
	const [newBoxName, setNewBoxName] = useState("");
	const [newBoxColor, setNewBoxColor] = useState("#ffffff");
	const [selectedColorBox, setSelectedColorBox] = useState(null);
	const [showing, setShowing] = useState(false);
	const [subfolders, setSubfolders] = useState([]);
	const [subfoldersid, setSubfoldersid] = useState();

	const [loader, setloader] = useState(false);

	const colors = [
		"red",
		"rgb(220, 180, 220)",
		"rgb(238, 232, 170)",
		"#444913",
		"rgb(118, 113, 113)",
		"green",
		"white",
	];
	const [selectedCategory, setSelectedCategory] = useState("TOP");
	const [selectedSubCategory, setSelectedSubCategory] = useState("");
	const [imageUrl, setImageUrl] = useState(null);
	const [isClicked, setIsClicked] = useState(false);
	const [show, setShow] = useState(false);
	const [dragopen, setDragopen] = useState(false);
	const [dragdata, setDragData] = useState([]);
	const [dragdataColl, setDragDataColl] = useState([]);
	const [selectedImageIndex, setSelectedImageIndex] = useState(null);
	const [boxImages, setBoxImages] = useState({});

	const [loading, setLoading] = useState(false);

	const [subfolderItems, setSubfolderItems] = useState([]);
	const [subfolderOutfits, setSubfolderOutfits] = useState([]);

	const [loadingji, setLoadingji] = useState(false);

	const client_id = clientId;

	const showfolder = async () => {
		setLoadingji(true);

		try {
			const respons = await getfolder(client_id);
			// console.log("Fetched folder data:", respons);
			if (Array.isArray(respons)) {
				setselecteddtFolderBox(
					respons.map((folder) => ({
						id: folder.id,
						name: folder.folder_name,
						color: folder.color,
					})),
				);
				await subfolderItemData();
			}
		} catch (error) {
			console.log("Error fetching folder data:", error);
		}finally {
			setLoadingji(false);
		  }
	};

	// useEffect(() => {
	//   showfolder();
	// }, []);
	const deletefolderfun = async (folderId) => {
		setLoadingji(true);

		try {
			await deletefolder(folderId);
			setselecteddtFolderBox((prevBoxes) =>
				prevBoxes.filter((box) => box.id !== folderId),
			);
		} catch (error) {
			console.error("Error deleting folder:", error);
		}finally {
			setLoadingji(false);
		  }
	};
	const deletesubfolderfun = async (sb_Id) => {
		setLoadingji(true);
		try {
			await deletesubfolder(sb_Id);
			setSubfolders((prevBoxes) => prevBoxes.filter((box) => box.id !== sb_Id));
		} catch (error) {
			console.error("Error deleting folder:", error);
		}finally {
			setLoadingji(false);
		  }
	};

	// console.log("newnewBoxes",newBoxes)
	// console.log("selecteddtFolderBox",selecteddtFolderBox)

	const handleShowing = (boxIndex) => {
		// alert(boxIndex)
		setSubfoldersid(boxIndex);
		subfolderItemData();
		subfolderOutfitData();
		setShowing(true);
	};
	// console.log("subfoldersid",subfoldersid)

	const handleClosing = () => {
		setShowing(false);
	};
	const [itemData, setItemData] = useState([]);
	// console.log("itemDataitemData",itemData)
	const handleOpen = () => {
		setShow(true);
	};

	const handledragOpen = () => {
		setDragopen(true);
	};

	const handleClosse = () => {
		setShow(false);
	};

	const hasImages = dragdataColl.length > 0;
	const [storedImages, setStoredImages] = useState([]);

	useEffect(() => {
		if (hasImages) {
			setStoredImages((prevImages) => [...prevImages, dragdataColl]);
		}
		showfolder();
	}, [dragdataColl, hasImages]);

	const handleDragClose = (index) => {
		const updatedData = dragdata.filter((_, i) => i !== index);
		setDragData(updatedData);
		console.log("dragdata", dragdata);
	};

	const handleImageClick = (index) => {
		setSelectedImageIndex(index);
	};
	const handleMouseOut = () => {
		setSelectedImageIndex(null);
	};

	const convertToBase64 = async (url) => {
		const response = await fetch(url);
        const blob = await response.blob();
    
        // Compress the image before converting to base64
        const compressedBlob = await imageCompression(blob, {
            maxSizeMB: 1,             // Maximum file size in MB (adjust as needed)
            maxWidthOrHeight: 800,    // Set maximum width/height (adjust based on your requirement)
            useWebWorker: true        // Use Web Worker for faster compression
        });
    
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result); // Get the base64 result
            reader.onerror = reject;
            reader.readAsDataURL(compressedBlob); // Convert compressed blob to base64
        });
    };

	const handlesetdropdowndata = async () => {
		setLoadingji(true);

		try {
			const base64Images = await Promise.all(
				dragdata.map((image) => convertToBase64(`${url}${image}`)),
			);
			const OutData = {
				client_id: clientId,
				images: base64Images,
				subfolder_id: subfoldersid,
				folder_id: "",
			};
			console.log("OutData", OutData);

			const response = await OutfitCrt(OutData);
			console.log("Outfit created successfully:", response);
			await subfolderOutfitData();
			getOutfitData();
			setDragImageBox(base64Images);
			handleClo2();
		} catch (error) {
			console.error("Error creating outfit:", error.message);
		}finally {
			setLoadingji(false);
		  }
	};

	const handleClearImages = () => {
		setDragData([]); // Clear the dragdata array
	};

	const [currentItem, setCurrentItem] = useState({
		imageUrl: null,
		inputValue: "",
		categorydefine: "",
		value: "",
		inputValue1: "",
		selecteddtBoxie: "",
		name: "",
		itemLocation: "",
		shoppingUrl: "",
	});
	const handleBrandChange = (e) => {
		setCurrentItem((prevState) => ({
			...prevState,
			inputValue: e.target.value,
		}));
	};

	console.log("dragdatadragdata", dragdata);

	const handleCategoryChange = (e) => {
		const selectedCategory = e.target.value;
		setSelectedCategory(selectedCategory);
		setSelectedSubCategory(""); // Reset selected subcategorydefine
	};

	const handleSubCategoryChange = (e) => {
		setCurrentItem((prevState) => ({ ...prevState, value: e.target.value }));
	};

	const handleSizeChange = (e) => {
		setCurrentItem((prevState) => ({
			...prevState,
			inputValue1: e.target.value,
		}));
	};

	const handleColorChange = (selecteddtBoxie, index) => {
		setCurrentItem((prevState) => ({ ...prevState, selecteddtBoxie }));
		setNewBoxColor(selecteddtBoxie);
		setSelectedColorBox(index);
	};

	const handleNameChange = (e) => {
		setCurrentItem((prevState) => ({ ...prevState, name: e.target.value }));
	};

	const handleItemLocationChange = (e) => {
		setCurrentItem((prevState) => ({
			...prevState,
			itemLocation: e.target.value,
		}));
	};

	const handleShoppingUrlChange = (e) => {
		setCurrentItem((prevState) => ({
			...prevState,
			shoppingUrl: e.target.value,
		}));
	};

	const [activeTab, setActiveTab] = useState(0);
	const handleTabClick = (tabIndex) => {
		setActiveTab(tabIndex);
	};

	const [sh, setSh] = useState(false);
	const handleClo = () => {
		setCurrentItem({
			imageUrl: null,
			inputValue: "",
			categorydefine: "",
			value: "",
			inputValue1: "",
			selecteddtBoxie: "",
			name: "",
			itemLocation: "",
			shoppingUrl: "",
		});
		setSh(false);
		setImageUrl(null);
		setIsClicked(false);
		setOpencam(false);
	};

	const handleSh = () => setSh(true);

	const [sh2, setSh2] = useState(false);
	const handleClo2 = () => {
		setDragData([]);
		setSh2(false);
	};
	const handleSh2 = () => setSh2(true);
	const [opencam, setOpencam] = useState(false);
	const handleClickCam = () => {
		setOpencam(true);
	};

	const handleClose = () => {
		setNewBoxName("");
		setNewBoxColor("#ffffff");
		setSelectedColorBox(null);
		setHide(false);
	};
	const handleDivClick = () => {
		setIsClicked(true);
	};

	const handleCapture = (imageSrc) => {
		setImageUrl(imageSrc);
		setCurrentItem((prevState) => ({ ...prevState, imageUrl: imageSrc }));
	};

	// console.log("currentItem",currentItem);

	///creating item
	const handleCreateNewBoxes = async () => {
		setLoadingji(true);

		try {
			const newItem = {
				client_id: clientId,
				img: currentItem.imageUrl,
				brand_name: currentItem.inputValue,
				color: currentItem.selecteddtBoxie,
				size: currentItem.inputValue1,
				category: currentItem.categorydefine,
				sub_category: currentItem.value,
				name: currentItem.name,
				item_location: currentItem.itemLocation,
				shopping_url: currentItem.shoppingUrl,
				subfolder_id: subfoldersid,
			};

			const response = await indexy(newItem);

			setItemData((prevState) => [...prevState, response]);
			setFormData((prevState) => [...prevState, response]);
			setAllitems((prevState) => [...prevState, response]);
			subfolderItemData((prevState) => [...prevState, response]);

			// Update newBoxes state with the new item
			setNewBoxes((prevState) =>
				prevState.map((box) =>
					box.parentIndex === selectedBoxIndex
						? { ...box, items: [...(box.items || []), response] }
						: box,
				),
			);

			// Close the modal and reset the state
			handleClo();

			setCurrentItem({
				imageUrl: null,
				inputValue: "",
				categorydefine: "",
				value: "",
				inputValue1: "",
				selecteddtBoxie: "",
				name: "",
				itemLocation: "",
				shoppingUrl: "",
			});
		} catch (error) {
			console.error("Error creating new item:", error);
		}finally {
			setLoadingji(false);
		  }
	};

	// console.log("itemData",itemData);
	// console.log("selecteddtFolderBox",selecteddtFolderBox);
	// console.log("subfolderItems",subfolderItems);

	/////

	const handleClosed = async () => {
		if (newBoxName.trim() !== "" && newBoxColor !== "#ffffff") {
			const FolderData = {
				user_id: clientId,
				folder_name: newBoxName,
				color: newBoxColor,
			};
			setLoadingji(true);


			try {
				const response = await folder(FolderData);
				console.log("Folder API response:", response);

				setselecteddtFolderBox((prevBoxes) => [
					...prevBoxes,
					{ name: response.folder_name, color: response.color },
				]);
				showfolder();
				setHide(false);
				// Clear the form inputs
				setNewBoxName("");
				setNewBoxColor("#ffffff");
			} catch (error) {
				console.error("Error posting folder data:", error);
			}finally {
				setLoadingji(false);
			  }
		}
	};

	/////

	const handleShow = () => setHide(true);

	const handleClick = async (folderId) => {
		setSelectedBoxIndex(folderId);
		try {
			const subfolderData = await getsubfolder(folderId);
			console.log("Subfolder data fetched:", subfolderData);
			setSubfolders(subfolderData);
		} catch (error) {
			console.error("Error fetching subfolder data:", error.message);
		}
	};

	const subfolderItemData = async () => {
		setloader(true);
		try {
			await getsubfolderdata(subfoldersid).then((data) => {
				setSubfolderItems(data);
			});
		} catch (error) {
			console.log(error);
		} finally {
			setloader(false);
		}
	};

	useEffect(() => {
		subfolderItemData();
	}, [subfoldersid]);

	// console.log("subfolderItems",subfolderItems);

	const subfolderOutfitData = async () => {
		setloader(true);
		try {
			const data = await getsubfolderoutfit(subfoldersid); // Fetch data for subfolder_id
			setSubfolderOutfits(data); // Set the state with the fetched outfits
		} catch (error) {
			console.log("Error fetching subfolder outfits:", error);
		} finally {
			setloader(false); // Stop loader after data is fetched
		}
	};

	useEffect(() => {
		if (subfoldersid) {
			subfolderOutfitData();
		}
	}, [subfoldersid]);

	const handleInputChange = (event) => {
		setNewBoxName(event.target.value);
	};
	const handleImageUpload = () => {
		const input = document.createElement("input");
		input.type = "file";
		input.accept = "image/*";
		input.onchange = (e) => {
			const file = e.target.files[0];
			if (file) {
				const reader = new FileReader();
				reader.onload = () => {
					setCurrentItem((prevState) => ({
						...prevState,
						imageUrl: reader.result,
					}));
					setLoading(false);
				};
				setLoading(true);
				reader.readAsDataURL(file);
			}
		};
		input.click();
	};

	const renderSubCategoriesOptions = () => {
		if (selectedCategory) {
			return subCategoriesMap[selectedCategory].map((value, index) => (
				<option key={index} value={value}>
					{value}
				</option>
			));
		} else {
			return null; // Render nothing if no categorydefine is selected
		}
	};
	// console.log("subfolders",subfolders)

	const subCategoriesMap = {
		TOP: [
			"Blouse",
			"Bodysuit",
			"Buttoned Shirt",
			"Camisole",
			"Cardigon",
			"Hoodie",
			"Jersey",
			"Polo Shirt",
			"Strapless",
			"Sweater",
			"Sweatshirt",
			"T-Shirt",
			"Tank Top",
			"Tunic",
			"Vest",
		],
		BOTTOM: [
			"DENIM",
			"LEGGINGS",
			"PANTS",
			"SHORTS",
			"SKIRT",
			"SWEATPANTS",
			"TIGHTS",
		],
		OUTWEAR: ["BLAZER", "COAT", "JACKET", "VEST"],
		"ONE PIECE": ["DRESS", "JUMPSUIT", "ROMPER"],
		BAG: [
			"BACKPACK",
			"BELT BAG",
			"BRIEFCASE",
			"CLUTCH",
			"CROSSBODY",
			"SHOULDER",
			"TOP HANDLE",
			"TOTE",
			"WALLET",
		],
		SHOES: [
			"BOOTS",
			"CLOGS",
			"FLATS",
			"HEELS",
			"LOAFERS",
			"OXFORD/DERBY",
			"SANDALS",
			"SNEAKERS",
			"WEDGES",
		],
		ACCESSORY: [
			"BELT",
			"GLASSES",
			"GLOVES",
			"HAIR ACCESSORIES",
			"HAT",
			"OTHER",
			"POCKET SQUARE",
			"SCARF",
			"SOCKS",
			"TIE",
		],
		JEWELRY: [
			"BRACLET",
			"EARRINGS",
			"NECKLACE",
			"OTHER JEWELRY",
			"RING",
			"WATCH",
		],
		SWIM: ["COVER-UP", "ONE-PIECE", "SEPARATES", "TRUNKS"],
	};
	const [selectedColor, setSelectedColor] = useState(null);

	const handleColorClick = (selecteddtBoxie) => {
		setSelectedColor(selecteddtBoxie);
	};

	// Define color options
	const colorOptions = [
		"#013701",
		"rgb(220, 180, 220)",
		"rgb(206, 206, 104)",
		"#444913",
		"rgb(118, 113, 113)",
		"red",
		"white",
	];

	const filteredSubfolderItems = subfolderItems.filter(
		(item) => String(item.subfolder_id) === String(subfoldersid),
	);
	console.log("filteredSubfolderItems", filteredSubfolderItems.length);

	return (

		<>
		{loadingji && (
  <div className="full-screen-loader">
    <div className="loader"></div>
  </div>
)}
		<div
			className="jbjbjiooi"
			style={{ background: "#fcfaed", marginBottom: "40px", minHeight: "80vh" }}
		>
			<div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
				{/* Render static boxes */}

				{/* Render existing boxes */}
				{selecteddtFolderBox.length > 0 &&
					selecteddtFolderBox.map((box, index) => (
						<div
							key={index}
							className={`dibba ${selectedBoxIndex === box.id ? "selected-boxIE" : ""}`} // Add class if box is selected
							style={{
								backgroundColor: box.color,
								cursor: "pointer",
								position: "relative",
							}}
							onClick={() => handleClick(box.id)} // Handle box click
						>
							<div className="texttttti">{box.name}</div>
							<div
								onClick={() => deletefolderfun(box.id)}
								className="foldericonhower"
							>
								<CloseIcon />
							</div>
						</div>
					))}

				<div
					style={{ cursor: "pointer" }}
					className="dibba4"
					onClick={handleShow}
				>
					<div className="pluss">+</div>
					<div className="texttttti4">Folder</div>
				</div>
			</div>

			{selectedBoxIndex !== null || selecteddtFolderBox.length !== 0 ? (
				<div style={{ textAlign: "center" }}>
					{selectedBoxIndex !== null && typeof selectedBoxIndex === "number" ? (
						<div></div>
					) : (
						<div></div>
					)}
				</div>
			) : (
				<div style={{ textAlign: "center" }}>
					<div className="coll1">You don't have any collection yet.</div>
					<div className="coll2">
						You can use collections to make a packing, shopping, or capsule
						list. Use the folder above the organize your collection based on
						your needs. You can use collections to make a packing, shopping, or
						capsule list. Use the folder above the organize your collection
						based on your needs.
					</div>
					<div className="coll3">
						Press "=" below to create your first collection.
					</div>
				</div>
			)}

			{selectedBoxIndex !== null && (
				<div>
					<div style={{ marginTop: "40px" }}></div>

					<div className="newboxcreted">
						{subfolders
							.filter((box) => box.folder_id === selectedBoxIndex)
							.map((box, index) => (
								<div
									key={index}
									className="subfolder-container"
									onClick={() => handleShowing(box.id)}
								>
									<div style={{ cursor: "pointer" }} className="njbiuiu">
										<div>
											<img
												loading="lazy"
												className="lnhug76f"
												src={`${url}${box.image}`}
											/>
											<span className="kjiii">{box.subfolder_name}</span>
										</div>
										<div className="hgy">
											{Array.isArray(filteredSubfolderItems)
												? `${filteredSubfolderItems.length} items`
												: "0 items"}
										</div>
									</div>
									<div
										className="subfoldericonhower"
										onClick={(event) => {
											event.stopPropagation();
											deletesubfolderfun(box.id);
										}}
									>
										<CloseIcon />
									</div>
								</div>
							))}
					</div>
				</div>
			)}

			<Modal centered show={showing} onHide={handleClosing} id="mnbvcxxc">
				<Modal.Body className="bodystylesad">
					<Modal.Header 
					className="modal_headerer"
						style={{
							margin: "0",
							padding: "0",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<i
							onClick={handleClosing}
							style={{ cursor: "pointer" }}
							class="bi bi-arrow-left"
						></i>
						{/* <ArrowBackIcon
            /> */}
						<h6 className="calh6776">FLTX</h6>
						<div style={{ color: "transparent" }}> .....</div>
					</Modal.Header>

					<div className="tab_container">
						<div className="tab_containerTabS">
							<CustomTab
								label="ITEMS"
								active={activeTab === 0}
								onClick={() => handleTabClick(0)}
							/>
							<CustomTab
								label="OUTFITS"
								active={activeTab === 1}
								onClick={() => handleTabClick(1)}
							/>
						</div>

						<div className="tab_content">
							{activeTab === 0 && (
								<>
									{loader ? (
										<div class="d-flex justify-content-center align-items-center jkkl">
											<div class="spinner-border" role="status">
												<span class="sr-only">Loading...</span>
											</div>
											<br />
										</div>
									) : (
										<div className="jkkl">
											<div className="row " style={{ justifyContent: "start" }}>
												{subfolderItems.map((item, index) => (
													<div
														style={{ backgroundColor: "transparent" }}
														key={index}
														className="col-md-4 border col-4"
													>
														<div style={{ height: "88%" }}>
															<SmoothImage src={`${url}${item.img}`} />
															{/* <div class="middle"> */}
															{/* <div className="buttonnn">
																	<div class="texttt">
																		<i
																			style={{
																				marginBottom: "4px",
																				fontSize: "15px",
																			}}
																			class="bi bi-eye-slash"
																		></i>{" "} */}
															{/* <VisibilityOffOutlinedIcon
                               
                              />{" "} */}
															{/* <br /> {item.iconsnamep}
																	</div>
																</div>
																<div className="buttonnn">
																	<div class="texttt">
																		{" "}
																		<i
																			style={{
																				marginBottom: "4px",
																				fontSize: "15px",
																			}}
																			class="bi bi-star"
																		></i> */}
															{/* <StarBorderIcon
                              />{" "} */}
															{/* <br /> {item.iconsnamef}
																	</div>
																</div>
																<div className="buttonnn" onClick={handleShow}>
																	<div class="texttt">
																		<div className="lull">
																			{" "}
																			<div className="lul">!</div>{" "}
																		</div>
																		<br /> {item.iconsnamed}
																	</div>
																</div>
																<div className="buttonnn" onClick={handleShow}>
																	<div class="texttt">
																		{" "}
																		<i
																			style={{
																				marginBottom: "4px", */}
															{/* fontSize: "15px",
																			}}
																			class="bi bi-archive"
																		></i> */}
															{/* <Inventory2OutlinedIcon
                                
                              />{" "} */}
															{/* <br /> {item.iconsnamea}
																	</div>
																</div> */}
															{/* </div> */}

															{/* //440 */}
														</div>
														<div style={{ width:"12%", fontWeight: "600", fontSize: "8px" }}>
															{item.sub_category}
														</div>
													</div>
												))}
											</div>
										</div>
									)}
									<div className="iconad" onClick={handleSh}>
										<div style={{ marginTop: "12px" }}>
											<i
												style={{
													textAlign: "center",
													marginTop: "10px",
													cursor: "pointer",
												}}
												class="bi bi-plus-lg"
											></i>{" "}
										</div>
										{/* <AddIcon onClick={() => setShowModal(true)} 
                /> */}
									</div>
									{/*1 ////////////////      ITEMSSSSSSSSSSSSS */}
									<Modal id="mnbvcxxc" centered show={sh} onHide={handleClo}>
										<Modal.Body
											style={{
												overflow: "auto",
												scrollbarWidth: "none",
												borderTopLeftRadius: "40px",
												borderBottomRightRadius: "40px",
											}}
										>
											<Modal.Header
												style={{
													margin: "0",
													padding: "0",
													alignItems: "center",
												}}
											>
												<i
													onClick={handleClo}
													style={{ marginBottom: "8px", cursor: "pointer" }}
													class="bi bi-arrow-left"
												></i>
												{/* <ArrowBackIcon 
                  /> */}
												<h6 className="calh6776CAL">CREATE ITEM</h6>
											</Modal.Header>

											<div>
												{currentItem.imageUrl ? (
													<div className="gyf67f">
														{loading ? (
															<div
																style={{
																	position: "absolute",
																	top: "50%",
																	left: "50%",
																	transform: "translate(-50%, -50%)",
																	zIndex: 1,
																}}
															>
																<div
																	class="spinner-border text-dark"
																	role="status"
																>
																	<span class="sr-only">Loading...</span>
																</div>
																{/* <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner> */}
															</div>
														) : (
															<img
																src={currentItem.imageUrl}
																alt="Uploaded"
																style={{
																	width: "100%",
																	height: "100%",
																}}
															/>
														)}
													</div>
												) : opencam ? (
													<div className="whitieboixxcoll">
														<Trialcam onCapture={handleCapture} />
													</div>
												) : (
													<div className="gyf67f" onClick={handleDivClick}>
														{isClicked ? (
															<div className="vyfyfy">
																<ButtonGroup
																	orientation="vertical"
																	aria-label="Vertical button group"
																	variant="contained"
																>
																	<Button onClick={handleClickCam}>
																		Photo from Camera
																	</Button>
																	<Button onClick={handleImageUpload}>
																		Photo from Library
																	</Button>
																</ButtonGroup>
															</div>
														) : (
															<div
																style={{ cursor: "pointer" }}
																className="alboix"
															>
																<div className="lkoplkop">
																	Click to upload a photo
																</div>
																<div className="oplkoplk">
																	We recommend using a product photo in a
																	flat-layposition. We'll automatically remove
																	the background for cleaner look.
																</div>
															</div>
														)}
													</div>
												)}
											</div>
											<div>
												<h2 className="lolii">BRAND*</h2>
												<input
													className="inphmn"
													value={currentItem.inputValue}
													onChange={handleBrandChange}
												/>
												<h2 className="lolii">CATEGORY*</h2>
												<select
													className="inphmn"
													value={currentItem.categorydefine}
													onChange={handleCategoryChange}
												>
													{Object.keys(subCategoriesMap).map(
														(categorydefine, index) => (
															<option key={index} value={categorydefine}>
																{categorydefine}
															</option>
														),
													)}
												</select>

												<h2 className="lolii">SUB-CATEGORY*</h2>
												<select
													className="inphmn"
													value={currentItem.value}
													onChange={handleSubCategoryChange}
												>
													<option value="">Select Sub-Category</option>
													{renderSubCategoriesOptions()}
												</select>

												<h2 className="lolii">SIZE*</h2>
												<input
													className="inphmn"
													value={currentItem.inputValue1}
													onChange={handleSizeChange}
												/>
												<h2 className="lolii">COLOR</h2>
												<div className="row color_items bnj">
													{colors.map((selecteddtBoxie, index) => (
														<div key={index} className="col-1">
															<div
																className={`color_listing ${currentItem.selecteddtBoxie === selecteddtBoxie ? "selectedborder" : ""}`}
																style={{ background: selecteddtBoxie }}
																onClick={() =>
																	handleColorChange(selecteddtBoxie)
																}
															></div>
														</div>
													))}
												</div>
												<h2 className="lolii">NAME</h2>
												<input
													className="inphmn"
													placeholder="Enter 2-3 word dexcription"
													value={currentItem.name}
													onChange={handleNameChange}
												/>
												<h2 className="lolii">ITEM LOCATION</h2>
												<input
													className="inphmn"
													placeholder="Item Location"
													value={currentItem.itemLocation}
													onChange={handleItemLocationChange}
												/>
												<h2 className="lolii">SHOPPING URL</h2>
												<input
													className="inphmn"
													placeholder="Enter Shopping URL"
													value={currentItem.shoppingUrl}
													onChange={handleShoppingUrlChange}
												/>
											</div>
										</Modal.Body>
										<Modal.Footer style={{ justifyContent: "center" }}>
											<div onClick={handleCreateNewBoxes}>
												<div className="footer_bnt_coll">
													SAVE <i class="bi bi-arrow-right"></i>
													{/* <ArrowRightAltIcon /> */}
												</div>
											</div>
										</Modal.Footer>
									</Modal>
								</>
								////////
							)}
							{activeTab === 1 && (
								<>
									{loader ? (
										<div class="d-flex justify-content-center align-items-center jkkl">
											<div class="spinner-border" role="status">
												<span class="sr-only">Loading...</span>
											</div>
											<br />
										</div>
									) : (
										<div className="jkkl">
											{subfolderOutfits.length > 0 ? (
												<div
													className="row"
													style={{ justifyContent: "start" }}
												>
													{subfolderOutfits.map((outfit, index) => (
														<div key={index} className="col-4 columnthreeColl">
															{outfit.images.map((imageObj, imgIndex) => (
																<img
																	key={imgIndex}
																	className="nullimageclassColl"
																	src={`${url}${imageObj.image}`}
																	alt={`subfolder-outfit-${outfit.id}-image-${imgIndex}`}
																/>
															))}
														</div>
													))}
												</div>
											) : (
												<div>
													<h3 className="padh3" style={{ fontSize: "16px" }}>
														No Outfit Created
													</h3>
												</div>
											)}
										</div>
									)}

									<div className="iconad" onClick={handleSh2}>
										<div style={{ marginTop: "12px", cursor: "pointer" }}>
											<i
												style={{ textAlign: "center", marginTop: "10px" }}
												class="bi bi-plus-lg"
											></i>
										</div>
										{/* <AddIcon onClick={() => setShowModal(true)} 
                /> */}
									</div>
									{/* 2///////// */}
									<Modal
										id="mnbvcxxcxoiop"
										centered
										show={sh2}
										onHide={handleClo2}
									>
										<div className="out_fit">
											<div className="header_outfit">
												<i
													onClick={handleClo2}
													style={{ cursor: "pointer" }}
													class="bi bi-arrow-left"
												></i>
												{/* <KeyboardBackspaceIcon  /> */}
												<div className="name_ou">Create Outfit</div>
												<i class="bi bi-bookmark"></i>
												{/* <LocalOfferIcon /> */}
											</div>

											<div className="body_outfit">
												<h3
													style={{ cursor: "pointer", fontSize: "12px" }}
													onClick={handleClearImages}
												>
													Clear
												</h3>

												<div className="collection_space">
													{dragopen
														? dragdata.map((data, index) => (
																<Draggable bounds="parent" key={index}>
																	<div
																		className="col-4"
																		style={{ position: "relative" }}
																		onMouseOver={() => handleImageClick(index)} // Call handleImageClick when hovering over the image
																		onMouseOut={handleMouseOut} // Call handleMouseOut when mouse moves out of the image
																	>
																		<img
																			src={`${url}${data}`}
																			alt={`item-${index}`}
																		/>
																		{selectedImageIndex === index && (
																			<div
																				style={{
																					position: "absolute",
																					top: 0,
																					right: 0,
																				}}
																			>
																				<i
																					onClick={() => handleDragClose(index)}
																					class="bi bi-x-lg"
																				></i>
																				{/* <CloseIcon  /> */}
																			</div>
																		)}
																	</div>
																</Draggable>
															))
														: null}
												</div>

												<div className="line_"></div>
												<div className="button_create" onClick={handleOpen}>
													<i
														style={{ color: "#fff", cursor: "pointer" }}
														class="bi bi-plus-lg"
													></i>
													{/* <AddIcon  /> */}
												</div>
											</div>
											<div>
												<div className="footer_outfit">
													<div
														className="footer_bnt_f"
														onClick={handlesetdropdowndata}
													>
														<div className="button_name_f">Save</div>
														<i
															style={{ color: "#fff" }}
															class="bi bi-arrow-right"
														></i>
														{/* <ArrowRightAlt  /> */}
													</div>
												</div>
											</div>
											<Modal
												id="lmkl"
												show={show}
												onHide={handleClosse}
												centered
											>
												<SelectItem
													allitem={allitem}
													hadleClose={handleClosse}
													formData={formData}
													setDragData={setDragData}
													handledragOpen={handledragOpen}
												/>
											</Modal>
										</div>
									</Modal>
								</>
								///
							)}
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				centered
				show={hide}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				style={{ overflow: "hidden" }}
				id="idy"
			>
				<Modal.Body className="bodystyle" >
					<div style={{ margin: "0", padding: "0", width: "100%",display: "flex", justifyContent:"space-between" }} >
						<div>.</div>
						<div>
							<h2 className="llpllp">CREATE FOLDER</h2>
						</div>
						<div onClick={handleClose}><CloseIcon/></div>
					</div>
					<h2 className="helh2">NAME*</h2>
					<input
						className="inph2"
						placeholder="Enter Name"
						value={newBoxName}
						onChange={handleInputChange}
					/>
					<h2 className="helh2">FOLDER COLOR</h2>
					<div style={{ display: "flex" }}>
						{colorOptions.map((selecteddtBoxie, index) => (
							<div
								key={index}
								className={`oo${index + 1}boxx ${selectedColorBox === index ? "selected-color" : ""}`}
								style={{ background: selecteddtBoxie }}
								onClick={() => handleColorChange(selecteddtBoxie, index)}
							></div>
						))}
					</div>

					<div className="margi"></div>
					<div className="line121"></div>
					<>
						{newBoxName.trim() !== "" && newBoxColor !== "#ffffff" ? (
							<div className="footer_bnt_coll" onClick={handleClosed}>
								<div className="button_name_f" >
									DO NOT STYLE
								</div>
								<i class="bi bi-arrow-right"></i>
								{/* <ArrowRightAltIcon /> */}
							</div>
						) : (
							<div className="footer_bnt_disabled">
								<div className="button_name_f ">DO NOT STYLE</div>
								<i class="bi bi-arrow-right"></i>
								{/* <ArrowRightAltIcon /> */}
							</div>
						)}
					</>
				</Modal.Body>
			</Modal>
		</div>
		</>
	);
};

export default Collection;
