import React, { useState } from 'react';
import './selectitems.css';

const url = "https://live.avertisystems.com/";



function SelectItem(props) {
    const { hadleClose,setDragData,handledragOpen,allitem } = props;
    const [selectedCategories, setSelectedCategories] = useState([]); // Store selected categories
    const [selectedColors, setSelectedColors] = useState([]); // Store selected colors


    const handleClick = (name, type) => {
        if (type === 'category') {
            setSelectedCategories(prevCategories => 
                prevCategories.includes(name) 
                    ? prevCategories.filter(cat => cat !== name)
                    : [...prevCategories, name]
            );
        } else if (type === 'color') {
            setSelectedColors(prevColors => 
                prevColors.includes(name)
                    ? prevColors.filter(color => color !== name)
                    : [...prevColors, name]
            );
        }
    };

    const filteredData = allitem.filter(item =>
        (selectedCategories.length === 0 || selectedCategories.includes(item.category)) &&
        (selectedColors.length === 0 || selectedColors.includes(item.color))
    );
   

    // Filter formData based on selected categories and colors

    const category = [
        { id: "1", name: "TOP" },
        { id: "2", name: "BOTTOM" },
        { id: "3", name: "OUTWEAR" },
        { id: "4", name: "ONE PIECE" },
        { id: "5", name: "BAG" },
        { id: "6", name: "SHOES" },
        { id: "7", name: "ACCESSORY" },
        { id: "8", name: "JEWELRY" },
        { id: "9", name: "SWIM" },
    ];

    const colors = ['red', 'rgb(220, 180, 220)', 'rgb(238, 232, 170)', '#444913', 'rgb(118, 113, 113)', 'green', 'white',];
    const handleClickdragopen = (imageUrl) => {
        setDragData(prevDragData => [...prevDragData, imageUrl]); // Append the new image URL to the existing dragdata array
        hadleClose();
        handledragOpen();
    }
const clearAll=()=>{
    setSelectedCategories([]); // Reset selectedCategories to empty array
    setSelectedColors([]); 
  

}
    return (
        <div className='select_itms'>
            <div className='selectitem_header'>
                <p className='clear_n' onClick={clearAll}>Clear</p>
                <p className='select_name'>Select items</p>
                <i style={{ cursor: "pointer", color:"black" }} onClick={hadleClose} class="bi bi-x-circle-fill"></i>
            </div>
            <div className='selectitem_body'>
                <div className='row catagory_items'>
                    {category.map((category_item, index) => (
                        <div key={index} className='col-4'>
                            <div className={`category_type ${selectedCategories.includes(category_item.name) ? 'selecteddi' : ''}`}
                                onClick={() => handleClick(category_item.name, 'category')}
                            >
                                {category_item.name}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='row color_items'>
                    {colors.map((color, index) => (
                        <div key={index} className='col-1'>
                            <div
                                className={`color_listhw ${selectedColors.includes(color) ? 'selecteddi123' : ''}`}
                                onClick={() => handleClick(color, 'color')}
                                style={{ background: color }}
                            >
                            </div>
                        </div>
                    ))}
                </div>
                <div className='select_item_list'>
                    <div className="row">
                        {filteredData.map((data, index) => (
                            <div className='col-4' key={index} onClick={() => handleClickdragopen(data.img)}>
                                <img  src={`${url}${data.img}`} alt={`item-${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectItem;
