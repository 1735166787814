import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { updateindexy } from "../api/auth";

import "./TabUnModal.css";
import { Row } from "react-bootstrap";

const TabUnModal = (props) => {
	const {
		handleclose,
		formData,
		selectProduct,
		setSelectProduct,
		handletoClose,
		setFormData,
		getBoxie,
	} = props;
	const [activeTab, setActiveTab] = useState(1);
	const [selecteddtBox, setSelecteddtBox] = useState({
		boxNumber: null,
		color: null,
	});
	const colors = [
		"red",
		"rgb(220, 180, 220)",
		"rgb(238, 232, 170)",
		"#444913",
		"rgb(118, 113, 113)",
		"green",
		"white",
	];
	
	const matchColor = colors.find((item) => item === selectProduct.color);

	const [brand, setBrand] = useState(selectProduct.brand_name || "");
	const [category, setCategory] = useState(selectProduct.category || "");
	const [subCategory, setSubCategory] = useState(selectProduct.sub_category || "");
	const [size, setSize] = useState(selectProduct.size || "");
	const [color, setColor] = useState(selectProduct.color || "");
	const [loadingji, setLoadingji] = useState(false);

	const [name, setName] = useState(selectProduct.name || "");
	const [itemLocation, setItemLocation] = useState(
		selectProduct.item_location || "",
	);
	const [shoppingUrl, setShoppingUrl] = useState(
		selectProduct.shopping_url || "",
	);
	const [selectedSeason, setSelectedSeason] = useState(
		selectProduct.season || "",
	);
	const [personalnote, setPersonalnote] = useState(
		selectProduct.personal_info || "",
	);
	const [itemDesc, setitemDesc] = useState(selectProduct.item || "");
	const [condition, setCondition] = useState(selectProduct.condiction || "");
	const [conditionDet, setConditionDet] = useState(
		selectProduct.condiction_detail || "",
	);
	const [origin, setOrigin] = useState(selectProduct.origin || "");
	const [purchaseDate, setPurchasedate] = useState(
		selectProduct.purchase_date || "",
	);

	const subCategoriesMap = {
		TOP: [
			"Blouse",
			"Bodysuit",
			"Buttoned Shirt",
			"Camisole",
			"Cardigon",
			"Hoodie",
			"Jersey",
			"Polo Shirt",
			"Strapless",
			"Sweater",
			"Sweatshirt",
			"T-Shirt",
			"Tank Top",
			"Tunic",
			"Vest",
		],
		BOTTOM: [
			"DENIM",
			"LEGGINGS",
			"PANTS",
			"SHORTS",
			"SKIRT",
			"SWEATPANTS",
			"TIGHTS",
		],
		OUTWEAR: ["BLAZER", "COAT", "JACKET", "VEST"],
		"ONE PIECE": ["DRESS", "JUMPSUIT", "ROMPER"],
		BAG: [
			"BACKPACK",
			"BELT BAG",
			"BRIEFCASE",
			"CLUTCH",
			"CROSSBODY",
			"SHOULDER",
			"TOP HANDLE",
			"TOTE",
			"WALLET",
		],
		SHOES: [
			"BOOTS",
			"CLOGS",
			"FLATS",
			"HEELS",
			"LOAFERS",
			"OXFORD/DERBY",
			"SANDALS",
			"SNEAKERS",
			"WEDGES",
		],
		ACCESSORY: [
			"BELT",
			"GLASSES",
			"GLOVES",
			"HAIR ACCESSORIES",
			"HAT",
			"OTHER",
			"POCKET SQUARE",
			"SCARF",
			"SOCKS",
			"TIE",
		],
		JEWELRY: [
			"BRACLET",
			"EARRINGS",
			"NECKLACE",
			"OTHER JEWELRY",
			"RING",
			"WATCH",
		],
		SWIM: ["COVER-UP", "ONE-PIECE", "SEPARATES", "TRUNKS"],
	};



	const handleupdate = async () => {
		const upDataform = {
			brand_name: brand,
			category: category,
			sub_category: subCategory,
			size: size,
			color: selecteddtBox.color || color,
			name: name,
			item_location: itemLocation,
			shopping_url: shoppingUrl,
			season: selectedSeason,
		};

		setLoadingji(true);
		try {
			// Call the update API with the product ID
			const response = await updateindexy(selectProduct.id, upDataform);
			console.log("Update API response:", response);
			getBoxie();
			
		} catch (error) {
			console.error("Error updating data:", error.message);
		}finally {
			handletoClose();
			handleclose();
			setLoadingji(false);
		  }
	};

	const handleupdate1 = async () => {
		const upDataform1 = {
			personal_info: personalnote,
			item: itemDesc,
			condiction: condition,
			condiction_detail: conditionDet,
			origin: origin,
			purchase_date: purchaseDate,
		};
		setLoadingji(true);

		try {
			const response = await updateindexy(selectProduct.id, upDataform1);
			console.log("Update API response:", response);

			setSelectProduct((prevProduct) => ({
				...prevProduct,
				...upDataform1,
			}));

			getBoxie();
		} catch (error) {
			console.error("Error updating data:", error.message);
		}finally {
			handletoClose();
			handleclose();
			setLoadingji(false);
		  }
	};

	console.log("selectedproduct", selectProduct);
	console.log("formdata", formData);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleClicked = (boxNumber, color) => {
		// Find the index of the clicked color in the colors array
		const colorIndex = colors.indexOf(color);

		// Set the selected box with the box number and color
		setSelecteddtBox({ color: colors[colorIndex] });

		// Update the color state to reflect the selected color
		setColor(colors[colorIndex]);

		// Log the index and color
		console.log(colorIndex + ": " + colors[colorIndex]);
	};

	const handleClick = (tabNumber) => {
		setActiveTab(tabNumber);
	};

	const handleBrandChange = (e) => {
		setBrand(e.target.value);
	};

	// const handleCategoryChange = (e) => {
	//   setCategory(e.target.value);
	// };
	const handleCategoryChange = (e) => {
		// const selectedCategory = e.target.value;
		setCategory(e.target.value);
		// Reset sub-category when category changes
		setSubCategory("");
	};

	const handleSubCategoryChange = (e) => {
		setSubCategory(e.target.value);
	};

	const handleSizeChange = (e) => {
		setSize(e.target.value);
	};

	const handleNameChange = (e) => {
		setName(e.target.value);
	};

	const handleItemLocationChange = (e) => {
		setItemLocation(e.target.value);
	};

	const handleShoppingUrlChange = (e) => {
		setShoppingUrl(e.target.value);
	};

	const handleShoppingPerInfo = (e) => {
		setPersonalnote(e.target.value);
	};
	const handleShoppingItemDesc = (e) => {
		setitemDesc(e.target.value);
	};
	const handleShoppingcondition = (e) => {
		setCondition(e.target.value);
	};
	const handleShoppingconditionDet = (e) => {
		setConditionDet(e.target.value);
	};
	const handleShoppingorigin = (e) => {
		setOrigin(e.target.value);
	};
	const handleShoppingpurchaseDate = (e) => {
		setPurchasedate(e.target.value);
	};

	const handleSeasonClick = (season) => {
		setSelectedSeason(season);
	};

	const renderSeasonOptions = () => {
		const seasons = ["SPRING", "SUMMER", "FALL", "WINTER"];
		return (
			<div style={{ display: "flex", flexWrap: "wrap" }}>
				{seasons.map((season) => (
					<button
						key={season}
						onClick={() => handleSeasonClick(season)}
						style={{
                            padding:"0",
							backgroundColor: selectedSeason === season ? "black" : "white",
							color: selectedSeason === season ? "white" : "black",
							height: "27px",
							fontSize: "10px",
							borderTopLeftRadius: "12px",
							borderBottomRightRadius: "12px",
							border: "1px solid black",
							width: "48%",
							margin: "1%",
							boxSizing: "border-box",
						}}
					>
						{season}
					</button>
				))}
			</div>
		);
	};

	const renderSubCategoriesOptions = () => {
		if (category && subCategoriesMap[category]) {
			return subCategoriesMap[category].map((option, index) => (
				<option key={index} value={option}>
					{option}
				</option>
			));
		}
		return null;
	};

	return (
		<>
		{loadingji && (
        <div className="full-screen-loader">
          <div className="loaderji"></div>
        </div>
      )}
		
		<div className="custom_tab22">
			<div className="tab_header22">
				<div
					className={activeTab === 1 ? "tab_item22 active" : "tab_item22"}
					onClick={() => handleClick(1)}
				>
					BASIC INFO
				</div>
				<div
					className={activeTab === 2 ? "tab_item22 active" : "tab_item22"}
					onClick={() => handleClick(2)}
				>
					MORE INFO
				</div>
				<div
					className={activeTab === 3 ? "tab_item22 active" : "tab_item22"}
					onClick={handleShow}
				>
					MEASUREMENT
				</div>
			</div>
			<div className="tab_content22">
				{activeTab === 1 && (
					<div className="tab_block22">
						<div>
							<h2 className="lolii">BRAND*</h2>
							<input
								className="inphmn"
								value={brand}
								onChange={handleBrandChange}
							/>
							<h2 className="lolii">CATEGORY*</h2>
							<select
								className="inphmn"
								value={category}
								onChange={handleCategoryChange}
							>
								<option value="TOP">TOP</option>
								<option value="BOTTOM">BOTTOM</option>
								<option value="OUTWEAR">OUTWEAR</option>
								<option value="ONE PIECE">ONE PIECE</option>
								<option value="BAG">BAG</option>
								<option value="SHOES">SHOES</option>
								<option value="ACCESSORY">ACCESSORY</option>
								<option value="JEWELRY">JEWELRY</option>
								<option value="SWIM">SWIM</option>
							</select>
							{/* {['TOP', 'BOTTOM', 'OUTWEAR', 'ONE PIECE', 'BAG', 'SHOES', 'ACCESSORY', 'JEWELRY', 'SWIM'].map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                ))} */}

							<h2 className="lolii">SUB-CATEGORY*</h2>
							<select
								className="inphmn"
								value={subCategory}
								onChange={handleSubCategoryChange}
							>
								<option value="">Select Sub-Category</option>
								{renderSubCategoriesOptions()}
							</select>

							<h2 className="lolii">SIZE*</h2>
							<input
								className="inphmn"
								value={size}
								onChange={handleSizeChange}
							/>

							<h2 className="lolii">COLOR</h2>
							<div className="row color_items">
								{colors.map((color, index) => (
									<div key={index} className="col-1">
										{/* <div
                      className={`color_listhw } ${color === matchColor? "bacgroundcolowrrwrwwr" : " "}`}
                      style={{ background: color }}
                    >
                    </div> */}
										<Row
											className={`color_listhw } `}
											style={{
												background: color,
												border:
													selecteddtBox.color === color || matchColor === color
														? "1px solid black"
														: "none",
											}}
											onClick={() => handleClicked(index, color)}
										></Row>
									</div>
								))}
							</div>
							<div style={{ marginBottom: "15px" }}></div>

							<h2 className="lolii">SEASON</h2>
							<div style={{ marginBottom: "10px" }}>
								{renderSeasonOptions()}
							</div>
							<h2 className="lolii">NAME</h2>
							<input
								className="inphmn"
								placeholder="Enter 2-3 word dexcription"
								value={name}
								onChange={handleNameChange}
							/>
							<h2 className="lolii">ITEM LOCATION</h2>
							<input
								className="inphmn"
								placeholder="Item Location"
								value={itemLocation}
								onChange={handleItemLocationChange}
							/>
							<h2 className="lolii">SHOPPING URL</h2>
							<input
								className="inphmn"
								placeholder="Enter Shopping URL"
								value={shoppingUrl}
								onChange={handleShoppingUrlChange}
							/>
						</div>
						<button style={{ padding: "3px" }} onClick={handleupdate}>
							Done
						</button>
					</div>
				)}

				{activeTab === 2 && (
					<div className="tab_block22">
						{/* Content for Tab 2 */}
						<h2 className="lolii">PERSONAL NOTE</h2>
						<input
							className="inphmin"
							placeholder="Enter a personal note about this item"
							onChange={handleShoppingPerInfo}
							value={personalnote}
						/>
						<h2 className="lolii">ITEM DESCRIPTION</h2>
						<input
							className="inphmin"
							type="text"
							placeholder="Enter item Description"
							onChange={handleShoppingItemDesc}
							value={itemDesc}
						/>
						<h2 className="lolii">CONDITION</h2>
						<input
             className="inphmn" 
             placeholder="Item Condition"
             onChange={handleShoppingcondition}
             value={condition}
              />
						<h2 className="lolii">CONDITION DETAILS</h2>
						<input 
            className="inphmn" 
            placeholder="Select main material" 
            onChange={handleShoppingconditionDet}
            value={conditionDet}
            />
						<h2 className="lolii">ORIGIN</h2>
						<input 
            className="inphmn" 
            placeholder="Eg China,Turky,Italy" 
            onChange={handleShoppingorigin}
            value={origin}
            />
						<h2 className="lolii">PURCHASE DATE</h2>
						<input 
            className="inphmn" 
            placeholder="Purchased Date" 
            onChange={handleShoppingpurchaseDate}
            value={purchaseDate}
              />
               <button
              style={{ padding: "3px" }}
              onClick={handleupdate1}
            >
              Save
            </button>
					</div>
				)}
			</div>
			<Modal id="loke" centered show={show} onHide={handleClose}>
				<Modal.Body style={{ overflowY: "auto" }}>
					<div className="tipo">Coming Soon</div>
					<div className="ticki">
						<div onClick={handleClose} className="footer_flio">
							<div className="button_name_flio">GO BACK</div>
							<i style={{ color: "black" }} class="bi bi-arrow-right"></i>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
		</>
	);
	
};


export default TabUnModal;
