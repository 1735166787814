import React, { useState, useEffect, useRef } from 'react'
import { BsFillSendFill, BsInfoCircle } from 'react-icons/bs'
// import Image from 'next/image'
import "./chatList.css";
import { IoClose } from "react-icons/io5";
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { GetGroup } from "../../../actions/event";

export default function NewChat() {
    const [messages, setMessages] = useState([
        { id: 1, sender: 'Alice', text: 'Welcome to the Eco-Gold chat!', timestamp: '10:00 AM' },
        { id: 2, sender: 'Bob', text: 'Hi everyone, excited to be here!', timestamp: '10:02 AM' },
        { id: 3, sender: 'Charlie', text: 'Let\'s discuss our eco-friendly initiatives.', timestamp: '10:05 AM' },
    ])
    const [newMessage, setNewMessage] = useState('')
    const [showGroupDetails, setShowGroupDetails] = useState(false)
    const [groupMembers, setGroupMembers] = useState([])
    const messagesEndRef = useRef(null)

    const groupDescription = "Welcome to the Eco-Gold Initiative! We're a passionate group dedicated to promoting sustainable practices and environmental consciousness."
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(scrollToBottom, [messages])

    useEffect(() => {
        const fetchGroupData = async () => {
            try {
                const groupData = await GetGroup();
                setGroupMembers(groupData.members);
            } catch (error) {
                console.error("Failed to fetch group data:", error);
            }
        };

        fetchGroupData();
    }, []);

    const handleSendMessage = (e) => {
        e.preventDefault()
        if (newMessage.trim() !== '') {
            const message = {
                id: messages.length + 1,
                sender: 'You',
                text: newMessage,
                timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            }
            setMessages([...messages, message])
            setNewMessage('')
        }
    }

    return (
        <Container fluid className="d-flex flex-column vh-95 bg_new_dark text-warning p-0">
            {/* Header */}
            <div className="bg_warning text_dark py-3 px-4 d-flex justify-content-between align-items-center">
                <Col>
                    <h1 className="fs-5 fw-bold">Chat Here</h1>
                </Col>
                <Col className="text-end d-flex">
                    <Button style={{ background: "transparent", border: "none", boxShadow: "none", padding: "0", margin: "0", textAlign: "end" }} onClick={() => setShowGroupDetails(!showGroupDetails)}>
                        <BsInfoCircle style={{ color: "#122620" }} size={18} />
                    </Button>

                    <Button style={{ width: "48px", background: "transparent", border: "none", boxShadow: "none", padding: "0", margin: "0", textAlign: "end" }} onClick={() => setShowGroupDetails(!showGroupDetails)}>
                        <IoClose style={{ color: "#122620", }} size={22} />
                    </Button>
                </Col>
            </div>

            {/* Group Description */}
            <div className="bg_new_dark text-warning py-3 px-4">
                <p className="mb-0 text_new_dark">{groupDescription}</p>
            </div>

            {/* Group Details */}
            {showGroupDetails && (
                <div className="bg_new_dark border-bottom border-warning pb-3 px-4 overflow-auto" style={{ maxHeight: '200px' }}>
                    <h2 className="fs-5 fw-bold text_new_dark mb-2">Group Members ({groupMembers.length})</h2>
                    <div className="g-3 d-flex" style={{ gap: "2rem" }}>
                        {groupMembers.map(member => (
                            <div key={member.id} className="d-flex align-items-center gap-2">
                                <div className="position-relative">
                                    <img
                                        src={member.avatar}
                                        alt={member.name}
                                        width={40}
                                        height={40}
                                        className="rounded-circle"
                                    />
                                    <div
                                        className={`position-absolute bottom-0 end-0 rounded-circle border border-dark ${member.status === 'online' ? 'bg-success' : 'bg-danger'}`}
                                        style={{ width: '12px', height: '12px' }}
                                    />
                                </div>
                                {/* <span>{member.name}</span> */}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Chat Messages */}
            <div className="flex-grow-1 py-3 px-4">
                {messages.map((message) => (
                    <Col style={{ width: "auto" }} key={message.id} className={`d-flex ${message.sender === 'You' ? 'justify-content-end' : 'justify-content-start'} mb-3`}>
                        <div className={`p-3 rounded-3 ${message.sender === 'You' ? 'bg-dark border border_warning' : 'bg-dark border border_warning'}`}>
                            <div className="fw-bold text_new_dark">{message.sender}</div>
                            <div className='text_new_dark'>{message.text}</div>
                            <div className="small text_new_dark">{message.timestamp}</div>
                        </div>
                    </Col>
                ))}
                <div ref={messagesEndRef} />
            </div>

            {/* Message Input */}
            <Form onSubmit={handleSendMessage} className="py-3 px-4 bg_new_dark border-top border_warning">
                <Row className="align-items-center new_btn_submit">
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder="Type your message..."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            className="text_new_warning"
                            style={{ background: "transparent", border: "none" }}
                        />
                    </Col>
                    <Col xs="auto" className='p-0'>
                        <Button type="submit" className=" bg_new_warning text_old_dark p-2 pe-2 me-0">
                            <BsFillSendFill size={24} style={{ width: "30px", height: "20px" }} />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}
