import moment from "moment";
import React, { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { addEvent, fetchEvents } from '../actions';
import AvertiLoader from "../loader";
import { Card, Form, Button, Col, Row } from "react-bootstrap";
import Reminders from './assets/reminders.png';
import Accordion from 'react-bootstrap/Accordion';
import './calendar.css';
import Select, { components } from "react-select";
import auth from "../api/auth";



const styles = {
    backgroundColor: "#fdf9f7"
}

const closesbtn = {
    fontSize: "18px",
    fontWeight: "900",
    background: "transparent",
    boxShadow: "none"
}

const blurbg = {
    background: "#f8f7fc",
    backdropFilter: "blur(20px )",
    webkitBackdropFilter: "blur(20px)",
    boxShadow: "rgba(50, 50, 93, 0.1) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.1) 0px 18px 36px -18px inset",
}

const btnsave = {
    margin: 0,
    height: "30px",
    fontWeight: "600",
}

const btnsave1 = {
    margin: 0,
    height: "30px",
    fontWeight: "600",
    background: "#fff",
    border: "none",
    color: "#000"
}

const event_input = {
    fontSize: "12px",
    fontWeight: "600"
}

const event_input1 = {
    fontSize: "12px",
    fontWeight: "600",
    height: "30px",
    width: "85px"
}

const event_input2 = {
    fontSize: "12px",
    fontWeight: "600",
    height: "30px",
    width: "85px"
}

const event_input_btn = {
    fontSize: "18px",
    fontWeight: "bold",
    padding: "0px",
    margin: "0px",
    backgroundColor: "gray",
    color: "#fff",
    height: "27px",
    width: "20px",
}

const accordianItem = {
    background: "transparent",
    border: "none"
}

function CalendarEventModal(props) {

    const printDocument = () => {
        window.print();
    };

    const sendEmail = () => {
        // implement email sending logic here
    };

    const eventFormRef = useRef();
    const [calendarLoader, showCalendarLoader, hideCalendarLoader] = AvertiLoader()

    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [stime] = useState();
    const [etime] = useState();
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { label: 'Finance', value: 'finance' },
        { label: 'Health', value: 'health' },
        { label: 'Business', value: 'business' },
        { label: 'Goals', value: 'goals' },
        { label: 'Children', value: 'children' }
    ];

    const saveEvent = async(e) => {
        const form = eventFormRef.current;
        let formError = false;

        if (form.title.value === '') {
            formError = true;
            window.toastr.error('Please enter event name');
            form.title.focus();
        }

        if (form.sdate.value === '') {
            formError = true;
            window.toastr.error('Please choose a date');
            form.sdate.focus();
        }

        if (!formError) {

            showCalendarLoader()

            const requestData = {
                title: form.title.value,
                description: form.description.value,
                location: form.location.value,
                type: 1,
                start_time: date.toString() + ' ' + (form.stime.value !== '' ? form.stime.value : '00:00') + ':00',
                end_time: date.toString() + ' ' + (form.etime.value !== '' ? form.etime.value : '23:59') + ':00',
                user_id: null,
                req_type: selectedOption ? selectedOption.value : null,
                participants: [826]
            }

            requestData.start_time = moment.utc(new Date(requestData.start_time))
            requestData.end_time = moment.utc(new Date(requestData.end_time))

            const savebutton = window.jQuery('#save-event')
            savebutton.attr('disabled', true)
            savebutton.html('Please wait ...')

            props.addEvent(requestData).then((data) => {
                window.toastr.success('Your event has been created successfully')
                form.reset()
                savebutton.attr('disabled', false)
                savebutton.html('Save')
                window.jQuery('#modal-centered').modal('hide')
                props.fetchEvents()
                hideCalendarLoader()
            })
                .catch((error) => {
                    window.toastr.error('Something went wrong. Please check and submit your data again!')
                    savebutton.attr('disabled', false)
                    savebutton.html('Save')
                    hideCalendarLoader()
                })
        }

    }

    const handleChange = (e) => {
        setDate(e.target.value);
    }

    const handleStartTime = (e) => {
        // console.log(e.target.value);
        // setStartTime(e.target.value);
    }

    const handleEndTime = (e) => {
        // setEndTime(e.target.value);
    }

    const [reminders, setReminders] = useState([]);

    const handleAddReminder = () => {
        setReminders([...reminders, {}]); // Add an empty object to the reminders array
    };


    return (
        <Fragment>
            {calendarLoader}
            <div id="modal-centered" className="modal fade">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{ background: "transparent" }}>
                        <div className="modal-header pb-0 pt-0" style={styles}>
                            <b className="create_event" style={{ fontSize: '16px' }}>Create a Request</b>
                            <button type="button" className="closes" onClick={() => window.jQuery('#modal-centered').modal('hide')} style={closesbtn}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={blurbg}>
                            <form ref={eventFormRef} className="form-horizontal">
                                <div className="row mb-2">
                                    <label htmlFor="event_title" className="label_event col-xs-3 col-3" style={{ fontWeight: "600" }}>Service*</label>
                                    <div className="col-xs-9 col-9">
                                        <input type="text" style={event_input} className="form-control event_input" id="event_title" name="title" placeholder="Event Service name here" />
                                    </div>
                                </div>
                                <div className="row  mb-2">
                                    <label className="label_event col-xs-3 col-3" style={{ fontWeight: "600" }}>Location*</label>
                                    <div className="col-xs-9 col-9">
                                        <input type="text" style={event_input} className="form-control event_input" name="location" placeholder="Location here ..." />
                                    </div>
                                </div>
                                <div className="row  mb-2">
                                    <label className="label_event col-xs-3 col-3" htmlFor="stime" style={{ fontWeight: "600" }}>Due Date</label>
                                    <div className="col-xs-9 col-9 d-flex" style={{ gap: "10px" }}>
                                        <input type="date" id="sdate" name="sdate" value={date} onChange={handleChange} className="form-control event_input" />
                                        <input type="time" id="stime" style={event_input} className="form-control event_input" name="stime" value={stime} onChange={handleStartTime} />
                                    </div>
                                </div>
                                <div className="row  mb-2">
                                    <label className="label_event col-xs-3 col-3" htmlFor="stime" style={{ fontWeight: "600" }}>End Time</label>
                                    <div className="col-xs-9 col-9 d-flex" style={{ gap: "10px" }}>
                                        <input type="date" id="edate" name="edate" value={date} onChange={handleChange} className="form-control event_input" />
                                        <input type="time" id="etime" style={event_input} className="form-control event_input" name="stime" value={etime} onChange={handleEndTime} />
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <label className="label_event col-xs-3 col-3" style={{ fontWeight: "600" }}>Category*</label>
                                    <div className="col-xs-9 col-9">
                                        <Select
                                            options={options}
                                            value={selectedOption}
                                            onChange={setSelectedOption}
                                            placeholder="Select a category..."
                                        />
                                    </div>
                                </div>

                                <div className="row  mb-3">
                                    <label className="label_event col-xs-3 col-3" htmlFor="sdate" style={{ fontWeight: "600" }}>Special Info</label>
                                    <div className="col-xs-9 col-9">
                                        <textarea className="form-control event_input" style={event_input} id="description" name="description" rows="3" placeholder="Enter Request note here..."></textarea>
                                    </div>
                                </div>

                                <hr className="mb-3" />
                            </form>
                            <div>
                                <Accordion>
                                    <Accordion.Item eventKey="0" style={accordianItem} id="accordian_item_div">
                                        <Accordion.Header id="accordian_head_btn" style={{ gap: "20px" }}>
                                            <img src={Reminders} alt="calendar" width="20px" height="20px" style={{ boxShadow: "none" }} />
                                            Reminders (1)
                                        </Accordion.Header>
                                        <Accordion.Body className="accordian_body_div">
                                            <div className="row  mb-2">
                                                <label className="label_event" htmlFor="stime" style={{ fontWeight: "600", padding: "0px", margin: "0px" }}>Date</label>
                                                {reminders.map((reminder, index) => (
                                                    <div key={index}>
                                                        <div className="col-xs-12 col-12 d-flex mt-2 justify-content-between" style={{ padding: "0px" }}>
                                                            <input type="date" id={`sdate_${index}`} name={`sdate_${index}`} className="form-control event_input" style={{ height: "30px", width: "125px" }} />
                                                            <input type="time" id={`stime_${index}`} className="form-control event_input" name={`stime_${index}`} style={event_input1} />
                                                            <input type="text" className="form-control event_input" name={`location_${index}`} style={event_input2} placeholder="Location here ..." />
                                                            <button style={event_input_btn} type="button" className="btn btn-sm" onClick={() => setReminders(reminders.filter((_, i) => i !== index))}><i className="bi bi-x"></i></button>
                                                        </div>
                                                        <hr className="mt-3" style={{ width: "95%", marginBottom: "0px" }} />
                                                    </div>
                                                ))}
                                                <Button variant="link" className="d-flex align-items-center" style={{ textDecoration: "none", gap: "3px", fontWeight: "500", margin: "0px", padding: "0px" }} onClick={handleAddReminder}>
                                                    <i class="bi bi-plus"></i>
                                                    Add Reminder
                                                </Button>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div className="modal-footer" style={blurbg}>
                            <div>
                                <button onClick={saveEvent} style={btnsave} type="button" className="btn button-save" id="save-event">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(null, { addEvent, fetchEvents })(CalendarEventModal);