
import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import Spinner from 'react-bootstrap/Spinner';

const Trialcam = ({ onCapture,setImagedata,imagedata }) => {
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // const capture = async () => {
  //   try {
  //     setLoading(true);

  //     const imageData = webcamRef.current.getScreenshot();
  //     const blob = await fetch(imageData).then((res) => res.blob()); // Convert to Blob

  //     setImageSrc(imageData);

  //     const formData = new FormData();
  //     formData.append('image_file', blob);
  //     formData.append('size', 'regular');

  //     const response = await fetch('https://api.remove.bg/v1.0/removebg', {
  //       method: 'POST',
  //       headers: {
  //         // 'X-Api-Key': 'sPydJdxWuNxgSxDVcHzQwdGr',  // Replace 'YOUR_API_KEY' with your actual remove.bg API key
  //          'X-Api-Key': 'QNoWT6GSTfTnijXdMUdTdHrZ',  // Replace 'YOUR_API_KEY' with your actual remove.bg API key
  //       },
  //       body: formData,
  //     });
  //     if (response.ok) {
  //       const result = await response.blob();
  //       const url = URL.createObjectURL(result);
  //       setImageSrc(url);
  //       onCapture(url); 
       
         
        
  //     } else {
  //       console.error('Failed to remove background:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const capture = async () => {
    try {
      setLoading(true);
  
      const imageData = webcamRef.current.getScreenshot();
      const blob = await fetch(imageData).then((res) => res.blob()); // Convert to Blob
  
      setImageSrc(imageData);
  
      // No background removal API call
  
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result; // This is the Base64 string
        onCapture(base64data);
        if (setImagedata) {
          setImagedata(prevImageData => [...prevImageData, base64data]);
        }
      };

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const toggleCamera = () => {
    setCameraOpen(prevState => !prevState);
  };

  return (
    <>
    <div>
    {cameraOpen && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', position: 'relative', height:"200px" }}>
          <div>
            {imageSrc ? (
              <img src={imageSrc} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            ) : (
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{ width: '100%', height: '100%' }}
              />
            )}
          </div>
        </div>
      )}
    </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>

        <button style={{background:"aliceblue"}} onClick={toggleCamera}>{cameraOpen ? 'Close Camera' : 'Open Camera'}</button>
        {cameraOpen && (
          <button style={{background:"aliceblue"}} onClick={capture}>Capture Photo</button>
        )}
      </div>
      {loading && (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      
    </>
  );
};


export default Trialcam;

