import React, { useState } from 'react';
import Section from '../layouts/Section';
import './zoom.css';
import { Modal, ModalHeader, ModalBody } from "react-bootstrap";
import { connect } from 'react-redux';
import { fetchMeetingList } from '../../actions';
import { useEffect } from 'react';
import auth from '../../api/auth';
import AvertiLoader from '../../loader';

function Zoom() {

    const [loader, showLoader, hideLoader] = AvertiLoader()

    const [open, setOpen] = useState(false);
    const [getList, setGetList] = useState([]);
    const [selectedMeeting, setSelectedMeeting] = useState();

    const handleOpen = (id) => {
        setOpen(true);
        setSelectedMeeting(id);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        const getMwwting = async () => {
            showLoader()
            const token = window.localStorage.getItem('AUTH_TOKEN');
            const response = await auth.get(`zoom/getinvite`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'Application/json',
                    'Accept': 'Application/json',
                },
            });
            setGetList(response.data.data);
            hideLoader()
            console.log(" itemList", response.data.data);
        };
        getMwwting();

    }, []);


    const handle_join =(data)=>{
        window.open(data, '_blank',"popup");
    }


    return (
        <div className=''>
            {loader}
            <Section allNotification={false} searchPopup={true} title={'Zoom Meeting'} />
            <div className='zoom' >
                <div className='zoom_header'>
                    <div className='container'>
                        {getList.map(list =>
                            <div className='row zoommainr' key={list} onClick={() => handleOpen(list)}>
                                <div className='col-6'>
                                    <h5 className='_finance_zoom'>{list.title}</h5>
                                    <p className='_finance_zoom'><span className='_start_zoom'>{list.start_time}</span><span className='_start_zoom'></span><span className='_start_zoom'></span></p>
                                </div>
                                <div className='col-6 avtarcol'>
                                    <div className="avatar-group avatar-group-chained">
                                        <span className="tooltip-wrapper">
                                            <span className="avatar avatar-circle  cursor-pointer">
                                                <img alt='img' className="avatar-img avatar-circle" src={ list.inviter&&list.inviter.profile_image} loading="lazy" />
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {selectedMeeting && (
                <Modal show={open} onHide={handleClose}  >
                    <div>
                        <ModalHeader closeButton>
                            <Modal.Title >
                                {/* <a href={selectedMeeting.join_url}> */}
                                    <button className='btn join-btn' onClick={()=>handle_join(selectedMeeting.join_url)}>
                                        <i className="bi bi-camera-video-fill"></i>
                                        <span>Join</span>
                                    </button>
                                {/* </a> */}
                            </Modal.Title>
                        </ModalHeader>

                        <ModalBody style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            <div className="">
                                <div className='zoom-content'>
                                    <h5 className='_finance_zoom'>{selectedMeeting.title}</h5>
                                </div>
                                <div className='zoom-description'>
                                    <p className='zoom_moda'>{selectedMeeting.des}</p>
                                </div>
                                <div className='zoom-list'>
                                    <table className="table meeting-table">
                                        <tbody className='zoom-tbody'>
                                            <tr className='zoom-tr'>
                                                <th className=''><i className="bi bi-calendar2-event-fill"></i>Date</th>
                                                <td className='meeting-td'>{selectedMeeting.start_time.split(" ").at(0)}</td>
                                            </tr>
                                            <tr className='zoom-tr'>
                                                <th className=''><i className="bi bi-alarm-fill"></i>Time</th>
                                                <td className='meeting-td'>{selectedMeeting.start_time.split(" ").at(1)}</td>
                                            </tr>
                                            <tr className='zoom-tr'>
                                                <th className=''><i className="bi bi-bell-fill"></i> Notify</th>
                                                <td className='meeting-td'>15 Minutes before</td>
                                            </tr>
                                            <tr className='zoom-tr'>
                                                <th className=''><i className="bi bi-person-fill"></i>Host</th>
                                                <td className='meeting-td'>{selectedMeeting.invited_by}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='zoom-client'>
                                    <h6>Clients<span>(3) </span> </h6>
                                    <div className='single-client'>
                                        <div className='client-avtar'>
                                            <img alt='img' className="avatar-img avatar-circle" src="https://images.unsplash.com/photo-1551836022-deb4988cc6c0?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=387&amp;q=80" loading="lazy" />
                                        </div>
                                        <div className='client-content'>
                                            <span><h6 className='zoom-cli'>Demetrius Felder</h6></span>
                                            <span><p className='zoom_moda'>Attendence Confirmed</p></span>
                                        </div>
                                    </div>
                                    <div className='single-client'>
                                        <div className='client-avtar'>
                                            <img alt='img' className="avatar-img avatar-circle" src="https://images.unsplash.com/photo-1551836022-deb4988cc6c0?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=387&amp;q=80" loading="lazy" />
                                        </div>
                                        <div className='client-content'>
                                            <span><h6 className='zoom-cli'>Demetrius Felder</h6></span>
                                            <span><p className='zoom_moda'>Attendence Confirmed</p></span>
                                        </div>
                                    </div>
                                    <div className='single-client'>
                                        <div className='client-avtar'>
                                            <img alt='img' className="avatar-img avatar-circle" src="https://images.unsplash.com/photo-1551836022-deb4988cc6c0?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=387&amp;q=80" loading="lazy" />
                                        </div>
                                        <div className='client-content'>
                                            <span><h6 className='zoom-cli'>Demetrius Felder</h6></span>
                                            <span><p className='zoom_moda'>Attendence Confirmed</p></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </div>
                </Modal>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    list: state.list
})

export default connect(mapStateToProps, { fetchMeetingList })(Zoom);
