import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CalendatTab.css";
import Spinner from 'react-bootstrap/Spinner';
import { ButtonGroup } from "react-bootstrap";
import Trialcam from "./Trialcam";
import CloseIcon from "@mui/icons-material/Close";
import { getoutfit } from "../api/auth";
import { outfitCal } from "../api/auth";
import { getcaloutfit } from "../api/auth";
import { deletecaloutfit } from "../api/auth";
import { selfieCal } from "../api/auth";
import { getselfieCal } from "../api/auth";
import { deletecalselfie } from "../api/auth";
import "./CalendatTab.css"



const CustomTab = ({ label, active, onClick }) => (
  <div className={`tabical ${active ? "active" : ""}`} onClick={onClick}>
    {label}
  </div>
);

const localizer = momentLocalizer(moment);

const CalanderTab = (props) => {
  const { clientId } = props;
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
    const [selfieData, setSelfieData] = useState([]);

    const [loadingji, setLoadingji] = useState(false);


  const [hasimages, setHasImages] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setImageUrl(null);
    setIsClicked(false);
    setOpencam(false);
  };
  const handleShow = () => {
    setImageUrl(null);
    setShow(true)
    setIsClicked(false);

  };

  const [date, setDate] = useState(false);
  const handleClosed = () => setDate(false);

  //////////////////////////////////////////////
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [opencam, setOpencam] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [imagedata, setImagedata] = useState([]);
  const [capturedImages, setCapturedImages] = useState([]);
  const [imageslocal,setImagesLocal]=useState([]);

  const [storedImages, setStoredImages] = useState([]);
  const [outfitID, setOutfitid] = useState([]);

  const client_id = clientId;
  
  const handleClickCam = () => {
    setOpencam(true);
  };
  const handleCapture = (imageSrc) => {
    setImageUrl(imageSrc); // Update the imageUrl state with the captured image URL
  };
  const handleDivClick = () => {
    setIsClicked(true);
  };

  const handleImageUpload = () => {
    // Open the file picker dialog
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  ////////////////////////////////////////////////

  // Function to handle item selection
  const handleItemClick = (outfit) => {
    if (selectedItems.includes(outfit)) {
      // If the item is already selected, deselect it
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem.id !== outfit.id)
      );
    } else {
      // Select the item and store its id
      setSelectedItems([...selectedItems, outfit]); // Include the full outfit object
    }
  };
  
  useEffect(() => {
    // Check if session storage is available
    if (typeof window !== "undefined" && window.sessionStorage) {
      const eventsFromStorage = JSON.parse(sessionStorage.getItem("events"));
      if (eventsFromStorage) {
        // If there are events in storage, set them in the state
        setEvents(eventsFromStorage);
      }
    }
  }, []);

  useEffect(() => {
    // Save events to session storage whenever events state changes
    if (typeof window !== "undefined" && window.sessionStorage) {
      sessionStorage.setItem("events", JSON.stringify(events));
    }
  }, [events, ]);
  // const handleClearEvents = () => {
  //   // Clear events from session storage
  //   if (typeof window !== "undefined" && window.sessionStorage) {
  //     sessionStorage.removeItem("events");
  //     // Clear events from state
  //     setEvents([]);
  //   }
  // };

  // Function to handle adding outfits to the selected date

  const handleAddOutfitsToDate = async () => {
    if (selectedDate && selectedItems.length > 0) {
      const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
      const CalData = selectedItems.map(item => ({
        outfit_id: item.id,  
        date: formattedDate,   
        client_id: client_id, 
      }));

  
      const isValid = CalData.every(({ outfit_id, date, client_id }) => {
        if (!outfit_id || !date || !client_id) {
          console.error("Missing required fields in CalData:", { outfit_id, date, client_id });
          return false; 
        }
        return true;
      });
  
      if (!isValid) {
        console.error("Invalid data. Please check your inputs.");
        return; 
      }
      setLoadingji(true);

  
      try {
        // Loop over each item and send the data one by one
        for (let data of CalData) {
          console.log("Sending to API:", data);
          const response = await outfitCal(data); // Call the API for each object individually
          console.log("API Response:", response); // Log the response from the API
        }
  
        // Logic for updating calendar events
        const existingEvent = events.find(event =>
          moment(event.start).isSame(selectedDate, "day")
        );
  
        const outfitDetails = selectedItems.map(item => ({
          outfit_id: item.id, // Only store outfit_id in events
        }));
  
        if (existingEvent) {
          existingEvent.outfits.push(...outfitDetails);
          setEvents([...events]); // Update events state
        } else {
          const newEvent = {
            title: "Outfits",
            start: selectedDate,
            end: selectedDate,
            allDay: true,
            outfits: outfitDetails, // Store selected outfit IDs in new event
          };
          setEvents([...events, newEvent]); // Add new event
        }
   
        await fetchCalendarOutfits(); 
        handleCloseModal(); 
        handleClosed();
      } catch (error) {
        console.error("Error adding outfits to date:", error.response?.data || error);
      }finally {
        setLoadingji(false);
        }
    }
  };
  
  
  
  

  
  
  
  


  console.log("eventiy", events);
  console.log("selectedImage", selectedImage);
  const handleSelectSlot = ({ start }) => {
    const hasImagesForDate = events.some(
      (event) =>
        moment(event.start).isSame(start, "day") &&
        event.outfits.some((outfit) => outfit.length > 0)
    );
    if (!hasImagesForDate) {
      setSelectedDate(start);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setSelectedDate(null);
    setShowModal(false);
    setSelectedItems([]); // Reset selected items when modal is closed
  };

  const Toolbar = ({ label }) => (
    <div className="toolbar-container">
      <div className="center-label" style={{ textAlign: "center" }}>
        {/* Always display the month in the center */}
        {label}
      </div>
    </div>
  );

  const CustomDay = ({ children, date }) => {
    // Check if there are images for the selected date
    const hasImagesForDate = events.some(
      (event) =>
        moment(event.start).isSame(date, "day") &&
        event.outfits.some((outfit) => outfit.length > 0)
    );

    // Handle click event only if the cell contains images
    const handleClick = () => {
      if (hasImagesForDate) {
        setSelectedDate(date);
        setShowModal(true);
      }
    };
    
   
   
    return (
      <div
        className={`date-cellofmybox ${
          hasImagesForDate ? "has-hdhdhimages" : ""
        }`}
        onClick={handleClick}
      >
        {children}
      </div>
    );
  };

  const dayPropGetter = (date) => {
    return {
      className: "date-cellofmybox",
    };
  };

  const eventImage =
    events.length > 0
      ? {
          outfits: events[0]?.outfits ?? [],
          start: events[0]?.start ? new Date(events[0]?.start) : null,
        }
      : {
          outfits: [],
          start: null,
        };

  console.log("eventImage", eventImage);
  const handleShowed = (image) => {
    console.log("image",image);
    setSelectedImage(image);
    setSelectedDate(image.start);
    setDate(true);
  };

  
  const getOutfitData = async () => {
    setLoadingji(true);

    try {
      const response = await getoutfit(client_id);
      console.log("API Response:", response); // Log the full response
      
      // If the response is an object, check if it has images
      if (Array.isArray(response)) {
        setStoredImages(response);
      } else {
        console.log("Unexpected data format:", response);
        // If the response is an object, you might want to check if it has an 'images' property
        if (response.images && Array.isArray(response.images)) {
          setStoredImages([response]); // Wrap in an array if it's a single outfit object
        }
      }
    } catch (error) {
      console.error("Error fetching outfit data:", error);
    } finally {
      setLoadingji(false);
    }
  };
  


  async function deleteoutfitcal(of_id) {
    setLoadingji(true);

    try{
      const response = await deletecaloutfit(of_id)
      if(response){
        fetchCalendarOutfits();
        console.log("Item deleted successfully:", response);
        handleClosed();
      }
    }catch(error){
      console.log("error on dlt",error.message)
    }finally {
			setLoadingji(false);
		  }
  }
  


  const fetchCalendarOutfits = async () => {
    			setLoadingji(true);

    try {
      const response = await getcaloutfit(client_id);
      setOutfitid(response);
  
      // Transform API response into calendar events
      const transformedEvents = response.reduce((acc, item) => {
        const date = moment(item.date).startOf('day').format();
        let existingEvent = acc.find(event => 
          moment(event.start).format() === date
        );
  
        // Check if item.outfit and item.outfit.images exist
        if (item.outfit && item.outfit.images) {
          const outfitData = {
            outfit_id: item.outfit_id,
            images: item.outfit.images,
            id: item.id,
          };
  
          if (existingEvent) {
            // Ensure the 'outfits' array exists before pushing new data
            if (!existingEvent.outfits) {
              existingEvent.outfits = [];
            }
            existingEvent.outfits.push(outfitData);
          } else {
            // Create new event if it doesn't exist
            acc.push({
              title: "Outfits",
              start: new Date(date),
              end: new Date(date),
              allDay: true,
              outfits: [outfitData], // Start with a new array
            });
          }
        }
  
        return acc;
      }, []);
  
      setEvents(transformedEvents);
  
    } catch (error) {
      console.error("Error fetching calendar outfits:", error);
    }finally {
			setLoadingji(false);
		  }
  };
  
  useEffect(() => {
    if (client_id) {
      getOutfitData();
      fetchCalendarOutfits();
    }
  }, [client_id]);

  
  useEffect(() => {
    if (client_id) {
      getOutfitData();
      fetchCalendarOutfits();
    }
  }, [client_id]);
  
  // Add these console logs
  useEffect(() => {
    console.log("Events:", events);
    console.log("Stored Images:", storedImages);
  }, [events, storedImages]);
  useEffect(() => {
    if (client_id) {
      getOutfitData();
      fetchCalendarOutfits();
    }
  }, [client_id]);
  
  // Add these console logs
  useEffect(() => {
    console.log("Events:", events);
    console.log("Stored Images:", storedImages);
  }, [events, storedImages]);

console.log("outfitID",outfitID)
  const CustomEvent = ({ event }) => (
    <div style={{ width: "39px", maxHeight: "60px" }}>
      {event.outfits && event.outfits.map((outfit, index) => (
        <div key={index} onClick={() => handleShowed(event)}>
          {outfit.images && outfit.images.map((img, idx) => (
            
            <img 
              key={idx} 
              src={`${url}${img.image}`}
              alt={`Outfit ${index + 1}`}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          ))}
        </div>
      ))}
    </div>
  );

  console.log("imagedata", imagedata);

  // const handleClickSaved = () => {
  //   // Add the temporary image URL to the imagedata state
  //   if (imagedata) {
  //     setCapturedImages((prevImagedata) => [...prevImagedata, imageUrl]);
  //   }
  //   if(setImagesLocal){
  //     setImagesLocal((prevImagedata) => [...prevImagedata, imageUrl])
  //   }
  //   if (imageUrl && selectedDate) {
  //     setSelfieData(prevSelfieData => [
  //       ...prevSelfieData,
  //       { date: selectedDate, imageUrl: imageUrl }
  //     ]);
  //   }
  //   setShow(false);
  // };
  const handleClickSaved = async () => {
    try {
      const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
      if (imageUrl && selectedDate) {
        // Make an API call to store the selfie data
        const response = await selfieCal({
          client_id: client_id, 
          selfi: imageUrl,
          date: formattedDate , 
        });

        console.log("API Response selfie:", response);
  
        if (response && response.id) {
          await fetchSelfieData();
        }
      }
      setShow(false); // Close the modal
    } catch (error) {
      console.error("Error saving the selfie:", error);
    }
  };


  const fetchSelfieData = async () => {
    try {
      const response = await getselfieCal(client_id);
      console.log("Fetched Selfie Data:", response);
      setSelfieData(response || []);
    } catch (error) {
      console.error("Error fetching selfie data:", error);
    }
  };

  useEffect(() => {
    fetchSelfieData();
  }, []);

  const handleDeleteSelfie = async (id) => { // Add async here
    try {
      const response = await deletecalselfie(id); // Await the API call
      if (response) {
        fetchSelfieData(); // Fetch the updated selfie data
        console.log("Item deleted successfully:", response);
        handleClosed(); // Close the modal or perform any other action
      }
    } catch (error) {
      console.log("Error on delete:", error.message); // Improved error logging
    }
  };
  

  const SelfieContent = () => {
    const filteredSelfies = selfieData.filter(item => 
      moment(item.date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD')
    );

    return (
      <div style={{ minHeight: "410px", maxHeight: "410px", overflowX: "hidden", overflowY: "auto", scrollbarWidth: "none" }}>
        {filteredSelfies.length > 0 ? (
          <div className="row" style={{ display: "flex", justifyContent: "start" }}>
            {filteredSelfies.map((selfieItem, index) => (
              <div key={index} className="col-4 columnthreee d-flex border box-item">
                <span 
                  className="close-icon" 
                  onClick={() => handleDeleteSelfie(selfieItem.id)}
                >
                  <CloseIcon />
                </span>
                <img
                  className="imgcls12"
                  src={`${url}${selfieItem.selfi}`}
                  alt={`Selfie ${index + 1}`}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="no-selfies-message">
            <h2>No Selfies</h2>
            <h4>No selfies found for this date.</h4>
          </div>
        )}
      </div>
    );
  };
  
  
  

  const url = "https://live.avertisystems.com/";
    //getting outfits
  

  return (
    <>
      {/* {hasimages && (
        <div>
          There are images on selected dates:
          {events.map((event, index) => (
            <div key={index}>{moment(event.start).format('MMMM DD, YYYY')}</div>
          ))}
        </div>
      )} */}

{loadingji && (
  <div className="full-screen-loader">
    <div className="loader"></div>
  </div>
)}

      <div style={{ marginBottom: "3rem" }}>
        <style>{`
       .rbc-event, .rbc-day-slot .rbc-background-event{
        background-color: transparent !important;
       }
       `}</style>
        <div>
      {/* <Button variant="danger" onClick={handleClearEvents}>
        Clear Events
      </Button> */}
      </div>
      <div className="calendar-wrapper">

        <Calendar

          localizer={localizer}
          events={events}
          selectable
          onSelectSlot={handleSelectSlot}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500, width: "100%", color:"black"}}
          components={{
            dayWrapper: CustomDay,
            toolbar: Toolbar,
            event: (props) => (
              <CustomEvent {...props} setHasImages={setHasImages} />
            ),
          }}
          dayPropGetter={dayPropGetter}
        />
      </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered id="mnbvcx">
        <Modal.Body
          className="bodystylesa"
          selectedDate={selectedDate}
        >
          <Modal.Header className="modal_headerer" style={{ margin: "0", padding: "0" , backgroundColor:"rgb(244, 244, 232)"  }} closeButton>
            <div style={{ display: 'flex',justifyContent:"space-between", width: '100%', alignItems: 'center',}}>
                  <div style={{fontSize:"7px"}}>
                    {selectedDate ? moment(selectedDate).format('MMMM DD, YYYY') : 'Modal heading'}
                  </div>
                  <h3 style={{ margin: 0 , textAlign:"center"}}>LINK AN OUTFIT</h3>
                  <p></p>
            </div>
          </Modal.Header>
          <div className="row" style={{ justifyContent: "start" }}>
          {storedImages && storedImages.map((item, index) => (
                <div
                  key={index}
                  className={`box-item ${
                    selectedItems.includes(item)
                      ? "col-4 columnthreee d-flex border seleimgbox007"
                      : "col-4 columnthreee d-flex border"
                  }`}
                  onClick={() => handleItemClick(item)}
                >
                 {item.images && item.images.map((img, idx) => (
                      <img
                        className="nullimageclasse"
                        key={idx}
                        src={`${url}${img.image}`}
                        alt={`item-${idx}`}
                      />
                  ))}
                </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <div>
            <div className="footer_bnt_coll" onClick={handleAddOutfitsToDate}>
              ADD OUTFITS TO DATE <i style={{color:"white"}} class="bi bi-arrow-right"></i>
            </div>
          </div>
        </Modal.Footer> 
      </Modal>

      {/* calander date model */}
      <Modal centered show={date} onHide={handleClosed} id="mnbvcxxc">
        <Modal.Body className="bodystylesad" selectedDate={selectedDate}>
        <Modal.Header
        className="modal_headerer"
            style={{ margin: "0", padding: "0", alignItems: "center", justifyContent:"space-between"}}
          >
            <i  onClick={handleClosed}  style={{ marginBottom: "8px" }} class="bi bi-arrow-left"></i>
            <h6 className="">
              {selectedDate
                ? moment(selectedDate).format("MMMM DD, YYYY")
                : " "}
            </h6>
            <div></div>
          </Modal.Header>

          {/* Custom tab and content */}
          <div className="tab_containerTab">
            <CustomTab
              label="OUTFIT"
              active={activeTab === 0}
              onClick={() => handleTabClick(0)}
            />
            <CustomTab
              label="SELFIE"
              active={activeTab === 1}
              onClick={() => handleTabClick(1)}
            />
          </div>

          <div className="tab-contentCalTab">
            {activeTab === 0 ? (
              <div>
                <div
                  className="row"
                  style={{ justifyContent: "start", minHeight: "410px" }}
                >
              {selectedImage &&
               selectedImage.outfits && (
                selectedImage.outfits.map((outfit, index) => (
                        <div
                          key={index}
                          className={`col-4 columnthreee d-flex border box-item`}
                        >

                        <span onClick={()=>deleteoutfitcal(outfit.id)} className="close-icdcal"><CloseIcon/></span>

                        {outfit.images && outfit.images.map((img, idx) => (
                              <img
                                className="nullimageclasse"
                                key={idx} 
                                src={`${url}${img.image}`}
                                alt={`Outfit ${index + 1}`}
                              />
                          ))}
                        </div>
                    ))
                  )}
                </div>
                <div className="iconad">
                  <div style={{marginTop:"12px"}}>
                <i style={{ textAlign: "center", marginTop: "10px",cursor:"pointer" }} onClick={() => setShowModal(true)} class="bi bi-plus-lg"></i>
                </div>
                </div>
              </div>
            ) : (

              <div>
              <SelfieContent/>
 
                <div className="iconad">
                <div style={{marginTop:"12px"}}>
                <i  onClick = {handleShow}  style={{ textAlign: "center", marginTop: "10px", cursor:"pointer"}} class="bi bi-plus-lg"></i>
                </div>
                </div>

                <Modal show={show} onHide={handleClose} centered id="mnbvcxLOP">
                  <Modal.Body className="modal_headerer" style={{ overflow: "hidden" }}>
                    <Modal.Header
                    className="modal_headerer"
                      style={{
                        margin: "0",
                        padding: "0",
                        alignItems: "center",
                        backgroundColor:"rgb(244, 244, 232)"
                      }}
                    >
                        <i  onClick={handleClose} style={{ marginBottom: "8px" }} class="bi bi-arrow-left"></i>
                      <h6 className="calh66">TAKE A SELFIE</h6>
                    </Modal.Header>
                    <div className="cvbnCAL">
                      We will automatically remove the background for a cleaner
                      look.
                    </div>

                    <div>
                      {imageUrl ? (
                        <div className="whitieboixxcTAB">
                          {loading ? (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 1,
                              }}
                            >
                              <Spinner animation="border" role="status">
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            </div>
                          ) : (
                            <>
                              <img
                                src={imageUrl}
                                alt="Uploaded"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            </>
                          )}
                        </div>
                      ) : opencam ? (
                        <div className="whitieboixxc">
                          <Trialcam
                            onCapture={handleCapture}
                            setImagedata={setImagedata}
                            imagedata={imagedata}
                          />
                        </div>
                      ) : (
                        <div
                          className="whitieboixxcTAB"
                          onClick={handleDivClick}
                        >
                          {isClicked ? (
                            <div className="bgroupmar">
                              <ButtonGroup
                                orientation="vertical"
                                aria-label="Vertical button group"
                                variant="contained"
                              >
                                <Button style={{backgroundColor:"#0d6efd"}} onClick={handleClickCam}>
                                  Photo from Camera
                                </Button>
                                <Button style={{backgroundColor:"#0d6efd"}} onClick={handleImageUpload}>
                                  Photo from Library
                                </Button>
                              </ButtonGroup>
                            </div>
                          ) : (
                            <div className="alboix">CHOOSE PHOTO</div>
                          )}
                        </div>
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer style={{ padding: "6px" }}>
                    <div className="footer_bnt_collieCAL" onClick={handleClickSaved}>
                      <div className="button_name_fnextCAL" >SAVE</div>
                      <i style={{color:"white"}} class="bi bi-arrow-right"></i>
                    </div>
                  </Modal.Footer>
                </Modal>
              </div>
            )}
          </div>
        </Modal.Body>
        {/* <Modal.Footer style={{justifyContent:"center"}}>
          <AddIcon onClick={() => setShowModal(true)} style={{textAlign:"center"}}/>
        </Modal.Footer> */}
      </Modal>
     
    </>
  );
};

export default CalanderTab;
