import React, { useState } from 'react'
import Section from "../../layouts/Section"
import { connect } from 'react-redux'
import { getChats } from "../../../actions/chat"
import Item1 from './item1';
import "./chatList.css";
import NewChat from './newchat';
import Modal from 'react-bootstrap/Modal';

function ChatList(props) {

  const { chats } = props;
  const boxnew = {
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    background: "#F4EBD0",
    border: "none",
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Section allNotification={false} searchPopup={false} title={'Chat'} />
      <ul className="list-group mg-top-50 container">
        {
          chats.map((chat, i) =>
            <li id="chatuserlist" className='list-group-item d-flex justify-content-between align-items-center'>
              <Item1 item={chat} />
            </li>
          )
        }
      </ul>
      <div className='mt-4'>
        <div className='container'>
          <div className='groupbox'>
            <div className='avtar_group_box'>
              <div className="card" style={boxnew}>
                <div className="card-body">
                  <div className="card-title d-flex align-items-center" style={{ gap: "15px" }}>
                    <div className='avtargroup'>
                      <img src='https://randomuser.me/api/portraits/men/25.jpg' alt='avatar' className='boxnew_avtar' />
                      <img src='https://randomuser.me/api/portraits/men/25.jpg' alt='avatar' className='boxnew_avtar' />
                      <img src='https://randomuser.me/api/portraits/men/25.jpg' alt='avatar' className='boxnew_avtar' />
                      <img src='https://randomuser.me/api/portraits/men/25.jpg' alt='avatar' className='boxnew_avtar' />
                    </div>
                    <h5 style={{ color: "red" }}><span>Group Name</span><span style={{ paddingLeft: "5px" }}>(10+)</span></h5>
                  </div>
                  <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                  <a href="#" className="newbell_ri"><i class="bi bi-bell-fill"></i></a>
                  <a onClick={handleShow} className="newbell_ch"><i class="bi bi-chat-left-text-fill"></i></a>

                  <Modal show={show} onHide={handleClose}>
                    <NewChat />
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  chats: state.chat.chats,
})

export default connect(mapStateToProps, { getChats })(ChatList)