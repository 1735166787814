import React, { useState, useEffect } from 'react';
import '../custom.css';
import { Modal, ModalHeader, ModalBody, Spinner } from "react-bootstrap";
import { Fragment } from 'react';
import auth from '../../api/auth';
import { connect } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Document/Custom-toastify.css';
import CheckTask from './CheckTask';
import Nav from 'react-bootstrap/Nav';
import "../Document/document.css";


function TaskList1(props) {
    const { user, userDetail, tasks, loader } = props;
    const [open, setOpen] = useState(false);
    const [task, setTask] = useState(null);
    const [reload, setReload] = useState(false);
    const [comments, setComments] = useState([]);

    const [showLoader, setShowLoader] = useState(true);

    console.log(showLoader, "showLoader")

    useEffect(() => {
        if (loader) {
            const timer = setTimeout(() => {
                setShowLoader(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [loader]);

    const [selectedFile, setSelectedFile] = useState("");
    // console.log(selectedFile, "selectedfile");

    const [selectedTask, setSelectedTask] = useState(null);
    const [selectedTask_id, setSelectedTaskId] = useState();


    useEffect(() => {
        if (selectedTask) {
            setSelectedTaskId(selectedTask.id);
        }
    }, [selectedTask]);

    const userId = user.id;

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const token = window.localStorage.getItem('AUTH_TOKEN');
        fetch(`https://live.avertisystems.com/api/v1/task_comment/get/${selectedTask_id}`, {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'Application/json',
                'Accept': 'Application/json',
            }
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setPosts(data.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [selectedTask_id]);

    const style_nav = {
        background: "#B3B5BB",
        fontWeight: "700",
        color: "#111111",
    }

    const style_ico = {
        color: "#000",
    }

    console.log(selectedTask, "selectedTask++++");



    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        const token = window.localStorage.getItem('AUTH_TOKEN');

        if (!selectedFile || !task) {
            alert('Please select a file and ensure there is a valid task');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', selectedFile);
            const response = await axios.post('https://live.avertisystems.com/api/v1/task_file/upload', formData, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    task_id: task.id,
                },
            });
            if (response.status === 200) {
                console.log('File uploaded successfully:', response.data);
                setSelectedFile("");
                getFile(task.id)
            } else {
                console.error('Failed to upload file');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const getFile = async (id) => {
        setReload(true);
        const token = window.localStorage.getItem('AUTH_TOKEN');
        const response = await axios.get(`https://live.avertisystems.com/api/v1/task_comment/get/${id}`, {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'Application/json',
                'Accept': 'Application/json',
            },
        });
        setSelectedFile(response.data.data.file);
        console.log(response.data.data.file, "response.data.data")
    }

    const handleOpen = (taskId) => {
        const selected = tasks.stages.find(task => task.id === taskId);
        console.log(selected, "selected")
        setSelectedTask(selected);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function formatTime(time) {
        const [hours, minutes] = time.slice(11, 16).split(':');
        const formattedHours = parseInt(hours);
        const amPm = formattedHours >= 12 ? 'PM' : 'AM';
        const displayHours = formattedHours > 12 ? formattedHours - 12 : formattedHours;
        return `${displayHours}:${minutes} ${amPm}`;
    }

    const postTaskComment = async () => {
        try {
            const token = window.localStorage.getItem('AUTH_TOKEN');
            const response = await axios.post(`https://live.avertisystems.com/api/v1/task_comment/store`, {
                comment: comments,
                task_id: selectedTask_id,
            }, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log(`Comment posted successfully for task_id`);
                setComments('');
                toast.success('Comment posted successfully!', {
                    position: "top-right",
                    autoClose: 3000,
                });
                // Add the following line to call the API after setting the comments
                await fetch(`https://live.avertisystems.com/api/v1/task_comment/get/${selectedTask_id}`, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'Application/json',
                        'Accept': 'Application/json',
                    }
                })
                    .then((res) => res.json())
                    .then((data) => {
                        console.log(data);
                        setPosts(data.data);
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            } else {
                console.error(`Failed to post comment for task_id`);
            }
        } catch (error) {
            console.error('Error posting task comment:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedTask_id === null) {
            return;
        }
        postTaskComment();
    };


    return (
        <div className='container'>
            {showLoader ? <div className="fix-loader"><Spinner /></div> : ''}
            <div>
                <div className='task_heading1' style={{ marginTop: "20px" }}>
                    <ToastContainer theme="colored" />

                </div>

                <div className="container">
                    <div className="row">
                        <Nav fill variant="tabs" style={{ gap: "7px" }}>
                            <Nav.Item style={{ display: "flex" }}>
                                <Nav.Link href="/task" style={style_nav}><i className="fa fa-list" aria-hidden="true" style={style_ico}></i>Task By Me </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/tasklist" style={style_nav} ><i className="bi bi-person-circle" style={style_ico}></i>Task By Professional</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                </div>
                <div className='task_list' style={{ marginTop: "20px" }}>
                    <div className='task_listhead'>
                        <table className="table _contacttable">
                            <tbody>
                                {tasks && tasks.stages && tasks.stages.length > 0 ? (
                                    tasks.stages.map((task, index) => (
                                        <Fragment key={index}>
                                            <tr onClick={() => handleOpen(task.id)}>
                                                <th className='task_listavtar task_lsit'>
                                                    <img style={{ boxShadow: "none" }} className="avatar-img avatar-circle" alt='avtar-img' src={user.profile_image} loading="lazy" />
                                                </th>
                                                <td className='task_listtext task_lsit'>
                                                    <div className='listtext1 d-grid' style={{ width: "120px" }}>
                                                        <span>
                                                            <p>
                                                                {task.title}
                                                            </p>
                                                        </span>
                                                        <span>
                                                            {task.description.slice(0, 15)}...
                                                        </span>
                                                    </div>
                                                    <div className='listtext1 d-grid'>
                                                        <span>
                                                            {formatTime(task.start_time)}
                                                        </span>
                                                        <span>
                                                            {formatTime(task.end_time)}
                                                        </span>
                                                    </div>
                                                    <label>
                                                        <span>{task.priority === 'critical' ? <span style={{padding:"3px 12px"}} className=' tag bg-critical-100'> Critical</span> : null}</span>
                                                        <span>{task.priority === 'High' ? <span style={{padding:"3px 12px"}} className=' tag bg-red-100'> High</span> : null}</span>
                                                        <span>{task.priority === 'medium' ? <span style={{padding:"3px 12px"}} className=' tag bg-medium-100'> Medium</span> : null}</span>
                                                        <span>{task.priority === 'low' ? <span style={{padding:"3px 12px"}} className=' tag bg-low-100'> Low</span> : null}</span>
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr style={{ height: "15px", boxShadow: "none" }}></tr>
                                        </Fragment>
                                    ))
                                ) : (
                                    <tr>
                                        <td style={{ textAlign: "center", fontSize: "16px", fontWeight: "700" }} colSpan="2">No tasks</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal show={open} onHide={handleClose} id="modal_style">
                <ModalHeader closeButton>
                    <Modal.Title>Task Activity</Modal.Title>
                </ModalHeader>
                <ModalBody className='p-0' style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    {selectedTask && (
                        <div className="modal-body">
                            <div className='_mytask'>
                                {/*<div className="_mytaskbody">
                                <h5>Attachment</h5>
                                <div className="mb-3">
                                    <label htmlFor="fileInput" className="form-label">
                                        Choose File
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="fileInput"
                                        onChange={handleFileChange}
                                    />
                                </div>
                                <button className="btn btn-primary" onClick={handleUpload}>
                                    Upload
                                </button>
                                </div>*/}

                                <div className='_mytaskbody mb-3'>
                                    <Fragment>
                                        <div className="row mb-3">
                                            <div className="col-4">
                                                Task Title :
                                            </div>
                                            <div className="col-8">
                                                <p>{selectedTask.title}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-4">
                                                Description :
                                            </div>
                                            <div className="col-8">
                                                <p>
                                                    {selectedTask.description}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-4">
                                                Start Time
                                            </div>
                                            <div className="col-8">
                                                <p>
                                                    {selectedTask.start_time}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-4">
                                                End Time
                                            </div>
                                            <div className="col-8">
                                                <p>
                                                    {selectedTask.end_time}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-4">
                                                Category
                                            </div>
                                            <div className="col-8">
                                                <p >
                                                    {selectedTask.req_type}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-4">
                                                Priority
                                            </div>
                                            <div className="col-8">
                                                <p>
                                                    <span>{selectedTask.priority === 'critical' ? <span className=' tag bg-critical-100'> Critical</span> : null}</span>
                                                    <span>{selectedTask.priority === 'high' ? <span className=' tag bg-red-100'> High</span> : null}</span>
                                                    <span>{selectedTask.priority === 'medium' ? <span className=' tag bg-medium-100'> Medium</span> : null}</span>
                                                    <span>{selectedTask.priority === 'low' ? <span className=' tag bg-low-100'> Low</span> : null}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </Fragment>
                                </div>
                                <div className='_mytaskbody mb-3'>
                                    <CheckTask selectedTask_id={selectedTask_id} />
                                </div>
                                <div className='_mytaskbody'>
                                    <h5>Activity</h5>
                                    <div id='list-group-item'>
                                        {Array.isArray(posts) && posts.length > 0 ? (
                                            posts.map((comm, index) => (
                                                <div className='row align-items-center list-group-item1' key={index}>
                                                    {comm.user_type === "CLIENT" ? (
                                                        <>
                                                            <div className='col-2 img_col_2'>
                                                                <img className="avatar-comment avatar-circle" src={user.profile_image} style={{ float: "right" }} loading="lazy" alt='new' />
                                                            </div>
                                                            <div className='col-10 ml-n2'>
                                                                <h6 className='text-sm text-dark' style={{ border: "none" }}></h6>
                                                                <p className='dblock text-sm'>{comm.comment}</p>
                                                                <p className="d-block">{new Date(comm.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                                            </div>
                                                        </>
                                                    ) : <>
                                                        <div className='col-10 ml-n1 p-0'>
                                                            <h6 className='text-sm text-dark' style={{ border: "none" }}>{comm.user_type}</h6>
                                                            <p className='dblock text-sm1'>{comm.comment}</p>
                                                            <p className="d-block">{new Date(comm.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                                        </div>
                                                        {userDetail["mappings"] !== undefined &&
                                                            userDetail?.mappings.map((user) => {
                                                                return (
                                                                    <div className='col-2' style={{ marginTop: "-30px" }} key={user.id}>
                                                                        {user.role === "LIFE_NAVIGATOR"
                                                                            ? <img className="avatar-comment avatar-circle" style={{ float: "right" }} src={user.avatar} loading="lazy" alt='new' />
                                                                            : null}
                                                                    </div>
                                                                );
                                                            })}
                                                    </>
                                                    }
                                                </div>
                                            ))
                                        ) : (
                                            <p>No Comments Here</p>
                                        )}

                                    </div>
                                    <div className='mt-4'>
                                        <h5>Comment</h5>
                                        <div className="px-3 py-2 row align-items-center">
                                            <div className="col-10 p-0 m-0" style={{ display: "flex" }}>
                                                <input
                                                    style={{ border: "2px solid #000" }}
                                                    type="text"
                                                    value={comments}
                                                    onChange={(e) => setComments(e.target.value)}
                                                    name="name"
                                                    required=""
                                                    className="form-control"
                                                    placeholder="Add a Comment..."
                                                    onSubmit={handleSubmit}
                                                />
                                            </div>
                                            <div className='col-2 p-0 m-0'>
                                                <button onClick={handleSubmit} className='btn btn_send p-0 m-0' type='submit' style={{ rotate: "45deg" }}>
                                                    <i className="bi bi-send taskic" style={{ fontSize: "20px" }}></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </div >
    )
}


const mapStateToProps = (state) => ({
    loader: state.event.loader,
    user: state.user.userDetail,
    userDetail: state.user.userDetail,
});

export default connect(mapStateToProps)(TaskList1);