import React, { useState } from "react";
import "./Ch1Calander.css";
import CalanderTab from "./CalanderTab";
// import Extra from './Extra';
import NullContent from "./NullContent";
import CalanderTabAgain from "./CalanderTabAgain";

function Fool() {
	return <div className="fool_tb">{/* <h1>KHATAM</h1> */}</div>;
}

function Ch1Calander(props) {
	const { storedImages, getOutfitData, setStoredImages, clientId } = props;
	const [activeTab, setActiveTab] = useState("NullContent");
	const [showicon, setShowicon] = useState(false);
	const [calanderdata, setCalanderdata] = useState([]);
	const [showcalender2, setShowCalender2] = useState(false);
	const [selfiedata, setSelfieData] = useState([]);

	const renderTabContent = () => {
		if (activeTab === "calendar") {
			return (
				<div className="cal_tb">
					<CalanderTab
						calanderdata={calanderdata}
						setSelfieData={setSelfieData}
            clientId={clientId}
					/>
				</div>
			);
		} else if (activeTab === "fool") {
			return <Fool />;
		} else if (activeTab === "NullContent") {
			return (
				<NullContent
					setCalanderdata={setCalanderdata}
					storedImages={storedImages}
					getOutfitData={getOutfitData}
					setStoredImages={setStoredImages}
				/>
			);
		} else if (activeTab === "Calendar2show") {
			return (
				<CalanderTabAgain
					calanderdata={calanderdata}
					selfiedata={selfiedata}
					clientId={clientId}
				/>
			);
		}
		// else if (activeTab === 'Extra'){
		//     return <Extra />;
		// }
	};

	console.log("calanderdata", calanderdata);
	const handleopen = () => {
		setShowicon(true);
		setShowCalender2(true);
	};
	const handlclosed = () => {
		setShowicon(false);
		setActiveTab("NullContent");
	};
	// const handleclose = () => {
	//   setDragopen(false);
	// }
	const ActiveTabOpen = () => {
		setActiveTab("calendar");
		handleopen();
	};
	const handleopencalender2 = () => {
		setShowCalender2(true);
		setActiveTab("calendar");
	};
	const handleclosecalender2 = () => {
		setShowCalender2(false);
		setActiveTab("Calendar2show");
	};

	return (
    <>
			<div className="rendeir">
				<div className="flier_pnt">
					{showicon ? (
						showcalender2 ? (
							<i
								style={{ marginTop: "10px", color: "black", fontSize: "20px" }}
								onClick={handleclosecalender2}
								class="bi bi-calendar-check"
							></i>
						) : (
							<i
								style={{ marginTop: "10px", color: "black", fontSize: "20px" }}
								onClick={handleopencalender2}
								class="bi bi-layout-sidebar-inset-reverse"
							></i>
						)
					) : (
						<div></div>
						// <i onClick={() => setActiveTab('fool')} class="bi bi-filter-square" style={{fontSize:"20px", color:"black"}}></i>
					)}
				</div>
				<div className="evnic">
					{showicon ? (
						<i
							onClick={handlclosed}
							class="bi bi-grid"
							style={{ color: "black", fontSize: "20px" }}
						></i>
					) : (
						<i
							onClick={() => ActiveTabOpen()}
							class="bi bi-calendar-event"
							style={{ color: "black", fontSize: "20px" }}
						></i>
					)}{" "}
				</div>
			</div>
			<div className="row">
				{renderTabContent()}
			</div>
      </>
	);
}

export default Ch1Calander;
